import React, { useEffect, useState } from "react"
import { FaChevronUp, FaStore, FaBuffer, FaRedhat } from "react-icons/fa"
import { MdOutlineFeaturedPlayList } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"
import "../App.css"
import { auth, firestore, storage } from "../firebase/cliente"
import {
  deleteDoc,
  doc,
  collection,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore"
import moment from "moment"
import { useAuth } from "../Components/authContext"
import Alert from "../Components/Alert"
import Load from "../Components/Load"

const Opciones = () => {
  const { clases, clientes, user } = useAuth()
  const [select, setSelect] = useState(null)
  const [cli, setCli] = useState(null)
  const [clasesSearch, setClasesSearch] = useState([])
  const [clientesSearch, setClientesSearch] = useState([])
  const [show, setShow] = useState(false)
  const [showCli, setShowCli] = useState(false)
  const [nuevo, setNuevo] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [load, setLoad] = useState(false)
  const [err, setErr] = useState("")
  const [search, setSearch] = useState("")
  const [searchCli, setSearchCli] = useState("")
  const navigate = useNavigate()
  const [dats, setDats] = useState({
    Nombre: "",
    Direccion: "",
    Numero: "",
    Credito: "",
    Limit: "0",
  })

  const Agregar = async () => {
    const stckRef = collection(firestore, "Clasificaciones")
    const Fecha = moment().format("DD-MMM-yyyy hh:mm:ss a")
    const add = { Nombre: search, Creado: Fecha }
    await addDoc(stckRef, add)
    setShow(true)
    setErr("Creado Con exito")
    setTimeout(() => {
      setShow(false)
      setSearch("")
    }, 2500)
  }
  const searchFilter = ({ target: { name, value } }) => {
    if (value) {
      const newData = clases.filter((item) => {
        const items = item.Nombre //Advance Search
        const itemData = items ? items.toUpperCase() : "".toUpperCase()
        const textData = value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      setClasesSearch(newData)
      setSearch(value)
    } else {
      setClasesSearch(clases)
      setSearch(value)
    }
  }
  const searchClie = ({ target: { name, value } }) => {
    if (value) {
      const newData = clientes.filter((item) => {
        const items = item.Nombre + item.Numero + item.Direccion //Advance Search
        const itemData = items ? items.toUpperCase() : "".toUpperCase()
        const textData = value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      setClientesSearch(newData)
      setSearchCli(value)
    } else {
      setClientesSearch(clientes)
      setSearchCli(value)
    }
  }
  const Editar = async () => {
    const refstck = doc(firestore, "Clasificaciones", select.id)
    await updateDoc(refstck, { Nombre: search })
    setShow(true)
    setSearch("")
    setSelect(null)
    setErr("Actualizado Con exito")
    setTimeout(() => {
      setShow(false)
    }, 2500)
  }
  const Eliminar = async () => {
    await deleteDoc(doc(firestore, "Clasificaciones", select.id))
    setSelect(null)
    setShow(true)
    setErr("Eliminado Con exito")
    setSearch("")
    setTimeout(() => {
      setShow(false)
    }, 2500)
  }

  const EliminarCli = async () => {
    await deleteDoc(doc(firestore, "Clientes", cli.id))
    setCli(null)
    setShow(true)
    setErr("Eliminado Con exito")
    setSearch("")
    setTimeout(() => {
      setShow(false)
      setClientesSearch(clientes)
    }, 500)
  }
  const GuardarCliente = async () => {
    const stckRef = collection(firestore, "Clientes")
    setLoad(true)
    const Fecha = moment().format("DD-MMM-yyyy hh:mm:ss a")
    var datos = {
      ...dats,
      Limit: parseFloat(dats.Limit),
      Creado: Fecha + " " + user.Nombre,
      Deuda: 0,
    }
    await addDoc(stckRef, datos)
    setShow(true)
    setErr("Creado Con exito")
    setTimeout(() => {
      setShow(false)
      setLoad(false)
      setShowCli(false)
      setNuevo(false)
      setDats({
        Nombre: "",
        Direccion: "",
        Numero: "",
        Credito: "",
        Limit: "0",
      })
    }, 2500)
  }
  const ActualizarCli = async () => {
    const stckRef = doc(firestore, "Clientes", cli.id)
    setLoad(true)
    const Fecha = moment().format("DD-MMM-yyyy hh:mm:ss a")
    var datos = { ...cli, Limit: parseFloat(cli.Limit) }
    await updateDoc(stckRef, datos)
    setShow(true)
    setErr("Actualizado Con exito")
    setTimeout(() => {
      setShow(false)
      setLoad(false)
      setShowCli(false)
      setCli(null)
    }, 2500)
  }
  const Onchanges = ({ target: { name, value } }) => {
    const result = value.replace(/\D/g, "")
    if (name == "Limit") {
      if (isNaN(result)) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 3500)
        setErr("Error, Solo puede ingresar numero")
      } else {
        setDats({ ...dats, [name]: result })
      }
    } else {
      setDats({ ...dats, [name]: value })
    }
  }
  const Onchanges2 = ({ target: { name, value } }) => {
    const result = value.replace(/\D/g, "")
    if (name == "Limit") {
      if (isNaN(result)) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 3500)
        setErr("Error, Solo puede ingresar numero")
      } else {
        setCli({ ...cli, [name]: result })
      }
    } else {
      setCli({ ...cli, [name]: value })
    }
  }
  useEffect(() => {
    setClasesSearch(clases)
  }, [clases])

  return (
    <>
      <Alert show={show} msg={err} />
      {showModal == true ? (
        <>
          <div className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto xs:w-full xs:h-full max-w-xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start  p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl xs:text-md font-semibold">
                    Agrega / Edita / Elimina Clasificaciones
                  </h3>
                </div>
                <div className="relative p-6 flex-auto">
                  <label
                    htmlFor="search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Buscar
                  </label>
                  <div className="relative">
                    <input
                      type="search"
                      id="Escrine"
                      onChange={searchFilter}
                      autoComplete="off"
                      value={search}
                      className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Escribe"
                    />
                  </div>
                  {select == null ? (
                    <ul className="my-4 space-y-3 h-48 overflow-x-scroll">
                      {clasesSearch.map((list) => {
                        return (
                          <li
                            key={list.id}
                            onClick={() => {
                              setSelect(list)
                              setSearch(list.Nombre)
                            }}
                          >
                            <div className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                              <span className="flex-1 ml-3 whitespace-nowrap">
                                {list.Nombre}
                              </span>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <button
                      className="mt-10 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setSelect(null)}
                    >
                      Deselecionar
                    </button>
                  )}
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false)
                      setSelect(null)
                    }}
                  >
                    Cerrar
                  </button>
                  {select == null && search != "" ? (
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        Agregar()
                      }}
                    >
                      Nueva Clasificacion
                    </button>
                  ) : null}
                  {select != null ? (
                    <>
                      <button
                        className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => Eliminar()}
                      >
                        Eliminar
                      </button>
                      <button
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => Editar()}
                      >
                        Actualizar
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed xs:hidden inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showCli == true ? (
        <>
          <div className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto xs:w-full xs:h-full max-w-xl">
              {load == true ? (
                <Load />
              ) : (
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {cli == null ? (
                    <>
                      {nuevo == true ? (
                        <div>
                          <div
                            className=" p-5
                          grid grid-cols-2 xs:grid-cols-1 justify-center gap-5  "
                          >
                            <div className="items-center  mb-6">
                              <div className="w-full">
                                <label
                                  className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                  for="inline-full-name"
                                >
                                  Nombre Completo
                                </label>
                              </div>
                              <div className="w-full">
                                <input
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                  name="Nombre"
                                  type="text"
                                  onChange={Onchanges}
                                  value={dats.Nombre}
                                />
                              </div>
                            </div>
                            <div className=" mb-6">
                              <div className="w-full">
                                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                  Direccion
                                </label>
                              </div>
                              <div className="w-full">
                                <input
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                  name="Direccion"
                                  autoComplete="off"
                                  onChange={Onchanges}
                                  value={dats.Direccion}
                                  placeholder="Municipio + Departamento + Direccion"
                                />
                              </div>
                            </div>

                            <div className=" mb-6">
                              <div className="w-full">
                                <label
                                  className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                  for="inline-password"
                                >
                                  Numero
                                </label>
                              </div>
                              <div className="w-full">
                                <input
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                  name="Numero"
                                  autoComplete="off"
                                  onChange={Onchanges}
                                  value={dats.Numero}
                                  placeholder=""
                                />
                              </div>
                            </div>

                            <div className=" mb-6">
                              <label className="w-full block text-gray-500 font-bold">
                                <input
                                  className="mr-2 leading-tight"
                                  type="checkbox"
                                  name="Credito"
                                  onChange={(e) =>
                                    setDats({
                                      ...dats,
                                      Credito: e.target.checked,
                                    })
                                  }
                                />
                                <span className="text-sm">
                                  Credito Disponible
                                </span>
                              </label>
                            </div>
                            {dats.Credito == true ? (
                              <>
                                <div className="items-center mb-6">
                                  <div className="w-full">
                                    <label
                                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                      for="inline-password"
                                    >
                                      Limite Credito
                                    </label>
                                  </div>
                                  <div className="w-full">
                                    <input
                                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                      name="Limit"
                                      type="number"
                                      onChange={Onchanges}
                                      value={dats.Limit}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="">
                              <div className="w-full"></div>
                              <div
                                onClick={() => {
                                  GuardarCliente()
                                }}
                                className="w-full"
                              >
                                <button
                                  className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                  type="button"
                                >
                                  Agregar Cliente
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <AnimatePresence>
                          <div className="flex items-start  p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl xs:text-md font-semibold">
                              Agrega / Edita / Elimina Clientes
                            </h3>
                          </div>
                          <div className="relative p-6 flex-auto">
                            <label
                              htmlFor="search"
                              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                            >
                              Buscar
                            </label>
                            <div className="relative">
                              <input
                                type="search"
                                onChange={searchClie}
                                autoComplete="off"
                                value={searchCli}
                                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Escribe"
                              />
                            </div>
                            {select == null ? (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="justify-center w-full  h-72 overflow-x-scroll"
                              >
                                <div className="grid grid-cols-4 gap-5">
                                  <th>Nombre</th>
                                  <th>Direccion</th>
                                  <th>Numero</th>
                                  <th>Deuda</th>
                                </div>
                                {clientesSearch.map((customer) => (
                                  <div
                                    key={customer.id}
                                    onClick={() => {
                                      setCli(customer)
                                    }}
                                    className=" grid grid-cols-4 space-x-5 gap-5 border-b border-gray-200 hover:bg-gray-100"
                                  >
                                    <motion.span
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                      className="py-3 px-6 text-left whitespace-nowrap"
                                    >
                                      {customer.Nombre}
                                    </motion.span>
                                    <motion.span
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                      className="py-3 px-6 text-left"
                                    >
                                      {customer.Direccion}
                                    </motion.span>
                                    <motion.span
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                      className="py-3 px-6 text-left whitespace-nowrap"
                                    >
                                      {customer.Numero}
                                    </motion.span>
                                    {customer.Credito == true ? (
                                      <motion.span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        className="py-3 px-6 text-left whitespace-nowrap"
                                      >
                                        Q{customer.Limit.toFixed(2)}
                                      </motion.span>
                                    ) : null}
                                  </div>
                                ))}
                              </motion.div>
                            ) : (
                              <button
                                className="mt-10 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setSelect(null)}
                              >
                                Deselecionar
                              </button>
                            )}
                          </div>
                        </AnimatePresence>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="xs:mt-12 p-5
                          grid grid-cols-2 xs:grid-cols-1 justify-center gap-5  "
                      >
                        <div className="items-center  mb-6">
                          <div className="w-full">
                            <label
                              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                              for="inline-full-name"
                            >
                              Nombre Completo
                            </label>
                          </div>
                          <div className="w-full">
                            <input
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              name="Nombre"
                              type="text"
                              onChange={Onchanges2}
                              value={cli.Nombre}
                            />
                          </div>
                        </div>
                        <div className=" mb-6">
                          <div className="w-full">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                              Direccion
                            </label>
                          </div>
                          <div className="w-full">
                            <input
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              name="Direccion"
                              autoComplete="off"
                              onChange={Onchanges2}
                              value={cli.Direccion}
                              placeholder="Municipio + Departamento + Direccion"
                            />
                          </div>
                        </div>

                        <div className=" mb-6">
                          <div className="w-full">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                              Numero
                            </label>
                          </div>
                          <div className="w-full">
                            <input
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              name="Numero"
                              autoComplete="off"
                              onChange={Onchanges2}
                              value={cli.Numero}
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className=" mb-6">
                          <label className="w-full block text-gray-500 font-bold">
                            <input
                              className="mr-2 leading-tight"
                              type="checkbox"
                              name="Credito"
                              onChange={(e) =>
                                setCli({
                                  ...cli,
                                  Credito: e.target.checked,
                                })
                              }
                            />
                            <span className="text-sm">Credito Disponible</span>
                          </label>
                        </div>
                        {cli.Credito == true ? (
                          <>
                            <div className="items-center mb-6">
                              <div className="w-full">
                                <label
                                  className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                  for="inline-password"
                                >
                                  Limite Credito
                                </label>
                              </div>
                              <div className="w-full">
                                <input
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                  name="Limit"
                                  type="number"
                                  onChange={Onchanges2}
                                  value={cli.Limit}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="">
                          <div className="w-full"></div>
                          <div
                            onClick={() => {
                              ActualizarCli()
                            }}
                            className="w-full"
                          >
                            <button
                              className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                              type="button"
                            >
                              Actualizar Cliente
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        className="mt-10 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setCli(null)}
                      >
                        Deselecionar
                      </button>
                    </>
                  )}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setShowCli(false)
                      }}
                    >
                      Cerrar
                    </button>
                    {cli == null ? (
                      <button
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          if (nuevo == false) {
                            setNuevo(true)
                          } else {
                            setNuevo(false)
                          }
                        }}
                      >
                        {nuevo == true ? "Cancelar " : "Nuevo Cliente"}
                      </button>
                    ) : null}
                    {cli != null ? (
                      <>
                        <button
                          className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => EliminarCli()}
                        >
                          Eliminar
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="opacity-25 fixed xs:hidden inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="h-screen ml-12 flex flex-row flex-wrap">
        <div className="bg-gray-100 flex-1 p-6 ">
          <div className="grid grid-cols-6 gap-6 xs:grid-cols-2">
            <Link to="/NuevoProducto" className="report-card">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div className="flex flex-row justify-between items-center">
                    <FaStore className="h6 text-indigo-700" />
                    <span className="rounded-full items-center p-2 flex text-white badge bg-teal-400 text-xs">
                      +
                      <FaChevronUp className="ml-2" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h1 className="h5"></h1>
                    <p>Crear Nuevo Producto</p>
                  </div>
                </div>
              </div>
              <div className="footer bg-white p-1 mx-4 border border-t-0 rounded rounded-t-none"></div>
            </Link>
            <div
              onClick={() => {
                if (showModal == true) {
                  setShowModal(false)
                } else {
                  setShowModal(true)
                }
              }}
              className="report-card"
            >
              <div className="card cursor-pointer">
                <div className="card-body flex flex-col">
                  <div className="flex flex-row justify-between items-center">
                    <FaBuffer className="h6 text-indigo-700" />
                    <span className="rounded-full items-center p-2 flex text-white badge bg-teal-400 text-xs">
                      + -
                      <FaChevronUp className="ml-2" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h1 className="h5"></h1>
                    <p>Modificar clasificaciones</p>
                  </div>
                </div>
              </div>
              <div className="footer bg-white p-1 mx-4 border border-t-0 rounded rounded-t-none"></div>
            </div>
            <div
              onClick={() => {
                setShowCli(true)
                setClientesSearch(clientes)
              }}
              className="report-card cursor-pointer"
            >
              <div className="card">
                <div className="card-body flex flex-col">
                  <div className="flex flex-row justify-between items-center">
                    <FaRedhat className="h6 text-indigo-700" />
                    <span className="rounded-full items-center p-2 flex text-white badge bg-teal-400 text-xs">
                      +
                      <FaChevronUp className="ml-2" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h1 className="h5"></h1>
                    <p>Crear Nuevo Cliente</p>
                  </div>
                </div>
              </div>
              <div className="footer bg-white p-1 mx-4 border border-t-0 rounded rounded-t-none"></div>
            </div>
            <div className="report-card cursor-pointer">
              <div className="card">
                <div
                  onClick={() => {
                    navigate("/NuevaVenta")
                  }}
                  className="card-body flex flex-col"
                >
                  <div className="flex flex-row justify-between items-center">
                    <MdOutlineFeaturedPlayList className="h6 text-indigo-700" />
                    <span className="rounded-full items-center p-2 flex text-white badge bg-teal-400 text-xs">
                      +
                      <FaChevronUp className="ml-2" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h1 className="h5"></h1>
                    <p>Crear Nueva Venta</p>
                  </div>
                </div>
              </div>
              <div className="footer bg-white p-1 mx-4 border border-t-0 rounded rounded-t-none"></div>
            </div>
            <div className="report-card">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div className="flex flex-row justify-between items-center">
                    <FaStore className="h6 text-indigo-700" />
                    <span className="rounded-full items-center p-2 flex text-white badge bg-teal-400 text-xs">
                      +
                      <FaChevronUp className="ml-2" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h1 className="h5"></h1>
                    <p>Crear Nuevo Producto</p>
                  </div>
                </div>
              </div>
              <div className="footer bg-white p-1 mx-4 border border-t-0 rounded rounded-t-none"></div>
            </div>
            <div className="report-card">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div className="flex flex-row justify-between items-center">
                    <FaStore className="h6 text-indigo-700" />
                    <span className="rounded-full items-center p-2 flex text-white badge bg-teal-400 text-xs">
                      +
                      <FaChevronUp className="ml-2" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h1 className="h5"></h1>
                    <p>Crear Nuevo Producto</p>
                  </div>
                </div>
              </div>
              <div className="footer bg-white p-1 mx-4 border border-t-0 rounded rounded-t-none"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Opciones
