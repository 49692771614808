import React, { useEffect, useState } from "react"

export default function Alert({ msg, show }) {
  return (
    <>
      <div
        className={`text-white fixed right-0 top-20 z-50 px-5 py-4 border-r-8 rounded-sm ${
          show ? "translate-x-0 right-10" : "translate-x-full"
        } ease-in-out duration-500 border-blue-700 bg-black/80  drop-shadow-lg`}
      >
        <p className="text-sm">
          <span className="mr-2 inline-block px-3 py-1 rounded-full bg-blue-600 text-white font-extrabold">
            i
          </span>
          {msg}
        </p>
      </div>
    </>
  )
}
