import { Navigate } from "react-router-dom"
import { useAuth } from "./authContext"
const ProtePan = ({ children }) => {
  const { user } = useAuth()

  if (user == null) {
    return <Navigate to="/Register" />
  }
  if (user.Rango != "Admin") {
    return <Navigate to="/Register" />
  }
  return children
}

export default ProtePan
