import React, { useState } from "react"
import { HiMenuAlt3 } from "react-icons/hi"
import logo from "../assets/png/logo.png"
import { MdOutlineDashboard } from "react-icons/md"
import { RiSettings4Line } from "react-icons/ri"
import { TbReportAnalytics } from "react-icons/tb"
import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai"
import { Link } from "react-router-dom"
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi"

export default function Slider() {
  const [open, setOpen] = useState(false)

  const menus = [
    { name: "Inicio", link: "/", icon: MdOutlineDashboard },
    { name: "Perfil", link: "Register", icon: AiOutlineUser },
    { name: "Mensages", link: "/", icon: FiMessageSquare },
    { name: "Estadisticas", link: "/", icon: TbReportAnalytics, margin: true },
    { name: "Listado", link: "Productos", icon: FiShoppingCart },
    { name: "Favoritos", link: "/", icon: AiOutlineHeart, margin: true },
    { name: "Opciones", link: "Opciones", icon: RiSettings4Line },
  ]
  return (
    <>
      <section className="bg-gray-800 flex gap-6 fixed z-50 h-full ">
        <div
          className={` h-full  ${
            open == true ? "w-50 duration-200" : "w-16 duration-500"
          }  text-white px-4 `}
        >
          <div className="py-3 flex justify-end">
            <HiMenuAlt3
              size={26}
              className="text-gray-500 cursor-pointer"
              onClick={() => setOpen(!open)}
            />
          </div>
          <div className="mt-4 space-y-auto flex flex-col gap-4 relative scroll">
            {menus?.map((menu, i) => (
              <Link
                to={menu?.link}
                key={i}
                className={` ${
                  menu?.margin && "mt-5"
                } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
              >
                <div>
                  {React.createElement(menu?.icon, {
                    size: "20",
                    color: "#e40a0b",
                  })}
                </div>
                <h2
                  style={{
                    transitionDelay: `${i + 1}00ms`,
                  }}
                  className={`whitespace-pre duration-100 ${
                    open == false
                      ? "opacity-0 translate-x-28 overflow-hidden"
                      : "translate-x-0 overflow-hidden"
                  }`}
                >
                  {menu?.name}
                </h2>
                <h2
                  className={`${
                    open && "translate-x-28"
                  } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 duration-100 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                >
                  {menu?.name}
                </h2>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
