import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Helmet } from "react-helmet"
import DatePicker from "react-datepicker"
import Alert from "../Components/Alert"
import Load from "../Components/Load"
import "react-datepicker/dist/react-datepicker.css"
import {
  ref,
  uploadString,
  getDownloadURL,
  getStorage,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage"
import { auth, firestore, storage } from "../firebase/cliente"
import { collection, addDoc } from "firebase/firestore"
import moment from "moment"
import { useAuth } from "../Components/authContext"
import { useNavigate } from "react-router-dom"

const NuevoProd = () => {
  const { clases } = useAuth()
  const [files, setFiles] = useState([])
  const [show, setShow] = useState(false)
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()
  const [err, setErr] = useState("")
  const [startDate, setStartDate] = useState(false)

  const [dats, setDats] = useState({
    Creado: "",
    Titulo: "",
    Descripcion: "",
    Precio: "",
    Costo: "",
    Ubicacion: "",
    Observacion: "",
    FechaEx: "",
    Existencia: "",
    Clasificacion: "",
    Img: "",
    Img2: "",
  })
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 2,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ))

  useEffect(() => {
    if (files != null) {
      return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
    }
  }, [])

  const Onchanges = ({ target: { name, value } }) => {
    const result = value.replace(/\D/g, ".")
    if (name == "Precio" || name == "Costo" || name == "Existencia") {
      if (isNaN(result)) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 3500)
        setErr("Error, Solo puede ingresar numero")
      } else {
        setDats({ ...dats, [name]: result })
      }
    } else {
      setDats({ ...dats, [name]: value })
    }
  }
  const guardar = async () => {
    setLoad(true)
    const stckRef = collection(firestore, "Productos")
    const Fecha = moment().format("DD-MMM-yyyy hh:mm:ss a")
    const urls = []
    for (let i = 1; i < 3; i++) {
      const d = i - 1
      const storageRef = ref(storage, `Productos/${dats.Titulo}/${i}`)
      await uploadBytes(storageRef, files[d])
      const ul = await getDownloadURL(storageRef)
      urls.push(ul)
    }

    const add = {
      Creado: Fecha,
      Titulo: dats.Titulo,
      Descripcion: dats.Descripcion,
      Precio: parseFloat(dats.Precio),
      Costo: parseFloat(dats.Costo),
      Ubicacion: dats.Ubicacion,
      Observacion: dats.Observacion,
      FechaEx: dats.FechaEx,
      Existencia: parseFloat(dats.Existencia),
      Clasificacion: dats.Clasificacion,
      Img: urls[0],
      Img2: urls[1],
    }
    await addDoc(stckRef, add)
    setShow(true)
    setErr("Creado Con exito")
    setTimeout(() => {
      setLoad(false)
      setFiles([])
      setShow(false)
      setDats({
        Creado: "",
        Titulo: "",
        Descripcion: "",
        Precio: "",
        Costo: "",
        Ubicacion: "",
        Observacion: "",
        FechaEx: "",
        Existencia: "",
        Clasificacion: "",
        Img: "",
        Img2: "",
      })
    }, 3500)
  }

  return (
    <div className="ml-16">
      <Helmet>
        <title>SPA | Nuevo Prod</title>
      </Helmet>
      <Alert show={show} msg={err} />
      {load == true ? (
        <Load />
      ) : (
        <div className="shadow bg-black/95 xs:overflow-hidden xs:rounded-md">
          <div className="space-y-6  bg-white px-4 py-5 sm:p-6">
            <div className="grid grid-cols-2  xs:grid-cols-1 w-full  gap-4">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  name="Titulo"
                  value={dats.Titulo}
                  autoComplete="off"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={Onchanges}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Titulo *
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  name="Precio"
                  autoComplete="off"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={dats.Precio}
                  onChange={Onchanges}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Precio *
                </label>
              </div>
              <div className="relative z-0  w-full mb-6 group">
                <input
                  name="Existencia"
                  value={dats.Existencia}
                  autoComplete="off"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={Onchanges}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Existencia *
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  name="Costo"
                  value={dats.Costo}
                  autoComplete="off"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={Onchanges}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Costo *
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <select
                  onChange={(e) => {
                    setDats({ ...dats, ["Clasificacion"]: e.target.value })
                  }}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                >
                  <option value=" " key={" "}>
                    {" "}
                  </option>
                  {clases.map((list) => {
                    return (
                      <option
                        key={list.Nombre}
                        className="text-sm text-indigo-800"
                      >
                        {list.Nombre}
                      </option>
                    )
                  })}
                </select>
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Clasificacion seleccionada: {dats.Clasificacion}
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  name="Observacion"
                  value={dats.Observacion}
                  autoComplete="off"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={Onchanges}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Observacion
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  name="Ubicacion"
                  value={dats.Ubicacion}
                  autoComplete="off"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={Onchanges}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Ubicacion
                </label>
              </div>
              <div className="relative z-20 w-full mb-6 group">
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0  peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Fecha Aproximada De Vencimiento
                </label>
                <DatePicker
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  onSelect={(date) => {
                    setStartDate(date)
                    setDats({
                      ...dats,
                      FechaEx: moment(date.toLocaleDateString()).format(
                        "DD-MMM-yyyy"
                      ),
                    })
                  }}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                />
              </div>
            </div>
            <div className="relative  z-0 w-full mb-6 group">
              <textarea
                name="Descripcion"
                className="block border rounded-sm border-blue-500  rounded-smpy-2.5 px-1.5 w-full text-sm text-gray-900 bg-transparent border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={Onchanges}
                required
              ></textarea>
              <label
                htmlFor="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 ml-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Descripcion
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Agrega Fotos De Tus Productos
              </label>

              <section className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                <div
                  className="space-y-1 text-center"
                  {...getRootProps({ className: "dropzone" })}
                >
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>
                        Seleciona Imagenes PNG, JPG, Maximo 2 imagenes
                      </span>
                      <input
                        {...getInputProps()}
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                  </div>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                </div>
              </section>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-0">
            <div className="bg-gray-50  px-4 py-3 text-right sm:px-6 cursor-pointer">
              <div
                onClick={(e) => {
                  if (
                    dats.Costo != "" &&
                    dats.Precio != "" &&
                    dats.Existencia != "" &&
                    dats.Titulo != ""
                  ) {
                    guardar()
                  } else {
                    setErr("Debe ingresar todos los campos con *")
                    setShow(true)
                    setTimeout(() => {
                      setShow(false)
                    }, 3500)
                  }
                }}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Guardar
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 cursor-pointer">
              <div
                onClick={(e) => {
                  navigate(-1)
                }}
                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Atras
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NuevoProd

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
}

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
}

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
}

const img = {
  display: "block",
  width: "auto",
  height: "100%",
}
