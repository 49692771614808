import React, { useEffect } from "react"
import "../App.css"
import HamburgerIcon from "../assets/hamburger.svg"
import StarIcon from "../assets/star.svg"
import FuelerIcon from "../assets/fueler.svg"
import Hero from "./Hero"
import About from "./About"
import MenuRandom from "./MenuRandom"

export default function Home() {
  return (
    <div className="h-full bg-pattern bg-repeat max-w-[1800px] mx-auto overflow-hidden">
      <Hero />
      <MenuRandom />
      <div className="h-[380px]"></div>
    </div>
  )
}
