import React, { useRef, useState } from "react"
import Alert from "../Components/Alert"
import { useAuth } from "../Components/authContext"
import { motion, AnimatePresence } from "framer-motion"
import { PDFViewer } from "@react-pdf/renderer"
import { BsCheck2All } from "react-icons/bs"
import DatePicker from "react-datepicker"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore"
import { firestore, storage } from "../firebase/cliente"
import moment from "moment"
import Load from "../Components/Load"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import "react-datepicker/dist/react-datepicker.css"
import Invoice from "../Components/Invoice"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"
import mt from "../assets/png/logo.png"
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: "5px",
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  item: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 2,
  },
  subitem: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 400,
  },
  textL: {
    textAlign: "right",
    display: "flex",
    padding: 5,
    // width: '66%', //<- working alternative
    flexShrink: 1,
    flexGrow: 2,
    fontSize: "12px",
  },
  img: {
    justifyContent: "center",
    marginRight: "150px",
  },
  content: {
    marginTop: "15px",
    borderTop: "1px solid black",
  },
  env: {
    flexGrow: 0,
    marginTop: "15px",
    flexShrink: 1,
    marginLeft: "14px",
    justifyContent: "center",
    fontSize: "14px",
  },

  row: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  left: {
    // width: '33%',//<- working alternative
    flexGrow: 0,
    width: "67%",
    flexShrink: 1,
  },

  right: {
    width: "33%",
    padding: 5,
    flexShrink: 1,
  },
})

export default function NuevaVenta() {
  const [page, setPage] = useState(0)
  const [cred, setCred] = useState(0)
  const [err, setErr] = useState(false)
  const [show, setShow] = useState(false)
  const [load, setLoad] = useState(false)
  const [creado, setCreado] = useState(false)
  const [lastSell, setLasSell] = useState({})
  const [searchCli, setSearchCli] = useState("")
  const [search, setSearch] = useState("")
  const [startDate, setStartDate] = useState("")
  const [file, setFile] = useState(null)
  const hiddenFileInput = useRef(null)
  const [ver, setVer] = useState(false)
  const [select, setSelect] = useState(null)
  const { stck, clientes, bolsa, user, bolsaSub, sells } = useAuth()
  const [stc, setStc] = useState({})
  const [clientesSearch, setClientesSearch] = useState([])
  const [stockSearch, setStockSearch] = useState([])
  const [dats, setDats] = useState({
    Nombre: "",
    Direccion: "",
    Numero: "",
    Total: "0",
    Credito: "",
    TotalCre: "0",
    Fecha: "",
    DiaPago: "",
    Transporte: "",
    Estado: "",
    idBuscador: "",
    Deuda: "0",
    Limit: "0",
    Credito: false,
    DiaPago: "",
  })
  const [datP, setDatP] = useState({
    NoDoc: "",
    FechaDoc: "",
    Tipo: "",
    Banco: "",
  })

  const Onchanges = ({ target: { name, value } }) => {
    setDats({ ...dats, [name]: value })
  }
  const OnchangesPa = ({ target: { name, value } }) => {
    setDatP({ ...datP, [name]: value })
  }

  const searchFilter = ({ target: { name, value } }) => {
    if (value) {
      const newData = stck.filter((item) => {
        const items = item.Titulo + item.Descripcion + item.Observacion //Advance Search
        const itemData = items ? items.toUpperCase() : "".toUpperCase()
        const textData = value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      setStockSearch(newData)
      setSearch(value)
    } else {
      setStockSearch(stck)
      setSearch(value)
    }
  }

  const searchClie = ({ target: { name, value } }) => {
    if (value) {
      const newData = clientes.filter((item) => {
        const items = item.Nombre + item.Numero + item.Direccion //Advance Search
        const itemData = items ? items.toUpperCase() : "".toUpperCase()
        const textData = value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      setClientesSearch(newData)
      setSearchCli(value)
    } else {
      setClientesSearch(clientes)
      setSearchCli(value)
    }
  }

  const cardProd = (list) => {
    return (
      <div
        onClick={() => {
          setSelect(list)
          setStc({ PrecioUnidad: list.Precio, Cantidad: list.Existencia })
        }}
        key={list.id}
        className="w-full p-3"
      >
        <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
          <div className="relative pb-48 overflow-hidden">
            <img
              className="absolute inset-0 h-full w-full object-contain hover:scale-125"
              src={list.Img}
              alt=""
            />
          </div>
          <div className="p-4">
            <span className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide text-xs">
              <h2 className="mt-2 mb-2  font-bold">{list.Titulo}</h2>
            </span>
            <p className="text-sm mt-4">{list.Descripcion}</p>
            <div className="mt-3 flex items-center justify-between">
              <span className="text-sm font-semibold">Precio</span>
              <span className="text-sm font-semibold">
                Q<span className="font-bold text-xl">{list.Precio}</span>
              </span>
            </div>
          </div>
          <div className="p-4 border-t border-b text-xs text-gray-700">
            <span className="flex items-center">
              <i className="text-lg text-green-400 mr-2">
                {list.Existencia != 0 ? <BsCheck2All /> : null}
              </i>
              Disponible: {list.Existencia}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const Onchanges2 = ({ target: { name, value } }) => {
    const result = value.replace(/\D/g, ".")
    if (isNaN(result)) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 2500)
      setErr("Error, Solo puede ingresar numero")
    } else {
      if (name == "Cantidad") {
        if (result > select.Existencia) {
          setShow(true)
          setTimeout(() => {
            setShow(false)
          }, 2500)
          setErr("Revasa limite de existencia")
        } else {
          setStc({ ...stc, [name]: result })
        }
      } else {
        setStc({ ...stc, [name]: result })
      }
    }
  }

  const Agregar = async () => {
    var pasa = false
    await bolsa.map((items) => {
      if (select.id == items.idProducto) {
        pasa = true
      }
    })
    if (pasa == true) {
      setShow(!show)
      setTimeout(() => {
        setShow((show) => !show)
      }, 3500)
      setErr("Este producto ya esta agregado al pedido")
    } else {
      const ref = doc(firestore, "Usuarios", user.id)
      const colRef = collection(ref, "Bolsa")
      addDoc(colRef, {
        AntesVenta: select.Existencia,
        Cantidad: parseFloat(stc.Cantidad),
        Cliente: dats.Nombre,
        Titulo: select.Titulo,
        Descripcion: select.Descripcion,
        Fecha: "",
        NoFactura: 0,
        PrecioOriginal: select.Precio,
        PrecioTotal: stc.PrecioUnidad * stc.Cantidad,
        PrecioUnidad: parseFloat(stc.PrecioUnidad),
        Ubicacion: select.Ubicacion,
        idBuscador: "",
        idProducto: select.id,
        Img: select.Img,
      })
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 3500)
      setErr("Se Agrego a tu bolsa")
      setSelect(null)
      setStc({})
    }
  }

  function handleChang(e) {
    setFile(e.target.files[0])
  }

  const handle = (e) => {
    hiddenFileInput.current.click()
  }

  const Guarda = async (Tipo) => {
    setLoad(true)
    const refbol = doc(firestore, "Usuarios", user.id)
    const Fecha = moment().format("DD-MMM-yyyy hh:mm:ss a")
    const NoFac = sells.length + 1
    if (Tipo == true) {
      const refCli = doc(firestore, "Clientes", dats.idCliente)
      var tot = dats.Deuda + bolsaSub
      await updateDoc(refCli, { Deuda: tot })
    }
    const add = {
      idCliente: dats.idCliente,
      Nombre: dats.Nombre,
      Numero: dats.Numero,
      Direccion: dats.Direccion,
      Transporte: dats.Transporte,
      Total: bolsaSub,
      Deuda: Tipo == true ? bolsaSub : 0,
      Credito: Tipo,
      No: NoFac,
      Creado: Fecha + " " + user.Nombre,
      DiaPago: dats.DiaPago,
    }
    const refSell = await addDoc(collection(firestore, "Ventas"), add)
    const List = []
    await bolsa.map(async (list) => {
      const refNuevo = collection(firestore, "ProductosVendidos")
      const refPro = doc(refbol, "Bolsa", list.id)
      const refStck = doc(firestore, "Productos", list.idProducto)
      const snap = await getDoc(refStck)
      const datos = snap.data()
      const up = snap.data().Existencia - list.Cantidad
      const add = {
        NoFac: NoFac,
        AntesVenta: datos.Existencia,
        Cantidad: list.Cantidad,
        Titulo: list.Titulo,
        Descripcion: list.Descripcion,
        Cliente: dats.Nombre,
        Creado: Fecha + " " + user.Nombre,
        idProducto: list.idProducto,
        Credito: Tipo,
        idVenta: refSell.id,
        PrecioUnidad: list.PrecioUnidad,
        PrecioTotal: list.PrecioTotal,
        PrecioOriginal: list.PrecioOriginal,
      }
      List.push(add)
      await addDoc(refNuevo, add)
      await updateDoc(doc(firestore, "Productos", list.idProducto), {
        Existencia: up,
      })
      await deleteDoc(refPro)
    })
    var p = {}
    if (Tipo == false) {
      const refVe = doc(firestore, "Ventas", refSell.id)
      const refPago = collection(refVe, "Pagos")
      var url = ""
      if (file != null) {
        const storageRef = ref(storage, `PagosRecibidos/${NoFac}`)
        await uploadBytes(storageRef, file)
        url = await getDownloadURL(storageRef)
      }
      const da = {
        Tipo: datP.NoDoc == "" ? "Efectivo" : datP.Tipo,
        NoDoc: datP.NoDoc,
        FechaDoc: datP.FechaDoc,
        Banco: datP.Banco,
        Creado: Fecha + " " + user.Nombre,
        Img: url,
      }
      p = da
      await addDoc(refPago, da)
    }
    setLasSell({ Venta: { ...add, id: refSell.id }, Pago: p, Ventas: List })
    setErr("Se A Creado Con Existo ")
    setShow(true)
    setTimeout(() => {
      setShow(false)
      setCred(0)
      setLoad(false)
      setCreado(true)
      setDats({
        Nombre: "",
        Direccion: "",
        Numero: "",
        Total: "0",
        Credito: "",
        TotalCre: "0",
        Fecha: "",
        DiaPago: "",
        Transporte: "",
        Estado: "",
        idBuscador: "",
        Deuda: "0",
        Limit: "0",
        Credito: false,
        DiasCre: "",
      })
      setDatP({
        NoDoc: "",
        FechaDoc: "",
        Tipo: "",
        Banco: "",
      })
      setFile(null)
    }, 3500)
  }

  return (
    <>
      {load == true ? (
        <div>
          <Load />
        </div>
      ) : (
        <div>
          <Alert msg={err} show={show} />
          <nav className="bg-gray-500 z-10 w-full">
            <div className=" px-4 py-3 mx-16">
              <div className="flex items-center">
                <ul className="cursor-pointer grid grid-cols-6 xs:grid-cols-2 mt-0 ml-6 space-x-2 text-sm font-medium">
                  {page != 0 ? (
                    <li className="p-2">
                      <div
                        onClick={() => {
                          setPage(0)
                        }}
                        className=" text-white hover:underline"
                        aria-current="page"
                      >
                        Ver
                      </div>
                    </li>
                  ) : null}
                  <li className="p-2">
                    <div
                      onClick={() => {
                        if (page != 1) {
                          setPage(1)
                          setClientesSearch(clientes)
                        } else {
                          setPage(0)
                        }
                      }}
                      className=" text-white hover:underline"
                      aria-current="page"
                    >
                      Agregar Cliente
                    </div>
                  </li>
                  <li className="p-2">
                    <div
                      onClick={() => {
                        if (page != 2) {
                          setPage(2)
                          setStockSearch(stck)
                        } else {
                          setPage(0)
                        }
                      }}
                      className="text-white hover:underline"
                    >
                      Agregar Productos
                    </div>
                  </li>
                  {bolsa.legth != "" && dats.Nombre != "" ? (
                    <li
                      className="p-2"
                      onClick={() => {
                        if (page != 3) {
                          setPage(3)
                        } else {
                          setPage(0)
                        }
                      }}
                    >
                      <div className="text-white hover:underline">
                        Completar
                      </div>
                    </li>
                  ) : null}
                  <li className="p-2">
                    <div className="text-gray-900 dark:text-white hover:underline">
                      Borrar
                    </div>
                  </li>

                  <li className="p-2">
                    <div className="text-gray-900 dark:text-white hover:underline">
                      Total Orden: {bolsaSub.toFixed(2)}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {page == 0 ? (
            <>
              <div className="grid grid-cols-2 mt-2 ml-20 mx-4 gap-6">
                <div className="relative z-0 w-full  group">
                  <input
                    autoComplete="off"
                    onChange={Onchanges}
                    name="Nombre"
                    value={dats.Nombre}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Nombre
                  </label>
                </div>
                <div className="relative z-0 w-full group">
                  <input
                    autoComplete="off"
                    name="Direccion"
                    value={dats.Direccion}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={Onchanges}
                  />
                  <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Direccion De Entrega
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    autoComplete="off"
                    name="Numero"
                    onChange={Onchanges}
                    value={dats.Numero}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Numero
                  </label>
                </div>
                <div className="relative z-0 w-full  group">
                  <input
                    autoComplete="off"
                    onChange={Onchanges}
                    name="Transporte"
                    value={dats.Transporte}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Transporte
                  </label>
                </div>
              </div>

              <div className="ml-20 z-0 w-full  group">
                <span className="font-bold text-lg text-black">
                  Total: Q{bolsaSub.toFixed(2)}
                </span>
              </div>

              <section className="min-h-[780px]">
                <div className="relative  ml-16 grid grid-cols-4 xs:grid-cols-1 gap-1">
                  {bolsa.map((list) => {
                    return (
                      <div key={list.id} className="w-full p-3">
                        <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
                          <div className="relative pb-48 overflow-hidden">
                            <img
                              className="absolute inset-0 h-full w-full object-contain hover:scale-125"
                              src={list.Img}
                              alt=""
                            />
                          </div>
                          <div className="p-4">
                            <span className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide text-xs">
                              <h2 className="mt-2 mb-2  font-bold">
                                {list.Titulo}
                              </h2>
                            </span>
                            <span
                              onClick={async () => {
                                await deleteDoc(
                                  doc(
                                    doc(firestore, "Usuarios", user.id),
                                    "Bolsa",
                                    list.id
                                  )
                                )
                              }}
                              className="inline-block cursor-pointer px-4 mx-2 py-1 leading-none bg-red-600 text-white rounded-full font-semibold uppercase tracking-wide text-xs"
                            >
                              <h2 className="mt-2 mb-2  font-bold">Eliminar</h2>
                            </span>
                            <p className="text-sm mt-4">{list.Descripcion}</p>
                            <div className="mt-3 flex items-center justify-between">
                              <span className="text-sm font-semibold">
                                Precio Unidad
                              </span>
                              <span className="text-sm font-semibold">
                                Q
                                <span className="font-bold text-xl">
                                  {list.PrecioUnidad.toFixed(2)}
                                </span>
                              </span>
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span className="text-sm font-semibold">
                                Cantidad
                              </span>
                              <span className="text-sm font-semibold">
                                <span className="font-bold text-xl">
                                  {list.Cantidad}
                                </span>
                              </span>
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span className="text-sm font-semibold">
                                Precio Total
                              </span>
                              <span className="text-sm font-semibold">
                                Q
                                <span className="font-bold text-xl">
                                  {list.PrecioTotal.toFixed(2)}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>
            </>
          ) : null}
          {page == 1 ? (
            <>
              <div className="border-0 ml-16 rounded-lg shadow-lg relative flex flex-col w-auto bg-white outline-none focus:outline-none">
                <AnimatePresence>
                  <div className="flex items-start  p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl xs:text-md font-semibold">
                      Selecciona Cliente
                    </h3>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <label
                      htmlFor="search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Buscar
                    </label>
                    <div className="relative">
                      <input
                        type="search"
                        onChange={searchClie}
                        autoComplete="off"
                        value={searchCli}
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Escribe"
                      />
                    </div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="justify-center w-full  h-72 overflow-x-scroll"
                    >
                      <div className="grid grid-cols-4 gap-5">
                        <th>Nombre</th>
                        <th>Direccion</th>
                        <th>Numero</th>
                        <th>Deuda</th>
                      </div>
                      {clientesSearch.map((customer) => (
                        <div
                          key={customer.id}
                          onClick={() => {
                            setDats({
                              ...dats,
                              idCliente: customer.id,
                              Nombre: customer.Nombre,
                              Numero: customer.Numero,
                              Direccion: customer.Direccion,
                              Deuda: customer.Deuda,
                              Credito: customer.Credito,
                              Limit: customer.Limit,
                            })

                            setShow(true)
                            setPage(0)
                            setTimeout(() => {
                              setShow(false)
                            }, 2500)
                            setErr("Se Selecciono Cliente")
                          }}
                          className="cursor-pointer grid grid-cols-4 space-x-8  border-b border-gray-200 hover:bg-gray-100"
                        >
                          <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="py-3 px-6 text-left whitespace-nowrap"
                          >
                            {customer.Nombre}
                          </motion.span>
                          <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="py-3 px-6 text-left"
                          >
                            {customer.Direccion}
                          </motion.span>
                          <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="py-3 px-6 text-left whitespace-nowrap"
                          >
                            {customer.Numero}
                          </motion.span>
                          {customer.Credito == true ? (
                            <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              className="py-3 px-6 text-left whitespace-nowrap"
                            >
                              Q{customer.Deuda.toFixed(2)}
                            </motion.span>
                          ) : null}
                        </div>
                      ))}
                    </motion.div>
                  </div>
                </AnimatePresence>
              </div>
            </>
          ) : null}
          {page == 2 ? (
            <>
              {select != null ? (
                <section className="bg-white">
                  <div className="xd:container px-6 py-10 mx-auto">
                    <div className="grid  grid-cols-2 justify-center content-center items-center">
                      <h1 className="text-3xl font-semibold text-gray-800  text-center capitalize ">
                        {select.Titulo}
                      </h1>
                      <h1
                        className="cursor-pointer text-xl font-bold text-red-600 text-center"
                        onClick={() => {
                          setSelect(null)
                        }}
                      >
                        X
                      </h1>
                    </div>

                    <div className="grid grid-cols-1 gap-8 mt-8 xd:mt-16 xd:grid-cols-2">
                      <div className="flex">
                        <img
                          className="object-cover w-3/6 h-auto rounded-lg xs:w-64"
                          src={select.Img}
                        />

                        <div className="flex flex-col justify-between py-6 xs:mx-6">
                          <div className="text-xl font-semibold text-gray-800 hover:underline ">
                            {select.Descripcion}
                          </div>
                        </div>
                      </div>
                      <div className="space-y-4 ml-12">
                        <div className="relative z-0 w-full  group">
                          <input
                            autoComplete="off"
                            onChange={Onchanges2}
                            name="PrecioUnidad"
                            value={stc.PrecioUnidad}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Precio Por Unidad
                          </label>
                        </div>

                        <div className="relative z-0 w-full  group">
                          <input
                            autoComplete="off"
                            onChange={Onchanges2}
                            name="Cantidad"
                            value={stc.Cantidad}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Cantidad
                          </label>
                        </div>

                        <button
                          onClick={() => {
                            if (stc.PrecioUnidad == "" && stc.Cantidad == "") {
                              setErr("Debe ingresar Todos los Campos")
                              setShow(true)
                              setTimeout(() => {
                                setShow(false)
                              }, 2500)
                            } else {
                              Agregar()
                            }
                          }}
                          type="button"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm  py-2.5 text-center px-14 mb-2"
                        >
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <section className="min-h-[780px]">
                  <div className="mx-20 z-20  relative pt-8">
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Que Buscas?
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="search"
                        autoComplete="off"
                        value={search}
                        onChange={searchFilter}
                        id="default-search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Busca el Producto que necesites"
                        required
                      />
                      <button
                        type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="relative  ml-16 grid grid-cols-4 xs:grid-cols-1 gap-1">
                    {stockSearch.map((list) => {
                      return cardProd(list)
                    })}
                  </div>
                </section>
              )}
            </>
          ) : null}
          {page == 3 ? (
            <>
              {creado == false ? (
                <>
                  {cred == 0 ? (
                    <>
                      <div className="pr-20 pl-10  mt-4 mx-12 grid xs:grid-cols-2 grid-cols-3 gap-5 w-full">
                        <button
                          onClick={() => {
                            Guarda(false)
                          }}
                          className="cursor-pointer text-white bg-[#050708] focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-center hover:bg-[#050708]/40 focus:ring-gray-600 mr-2 mb-2"
                        >
                          Guardar Como Efectivo
                        </button>

                        <button
                          onClick={() => {
                            setCred(2)
                          }}
                          className="cursor-pointer text-white bg-[#050708] focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-center hover:bg-[#050708]/40 focus:ring-gray-600 mr-2 mb-2"
                        >
                          Guardar Como Deposito/Transferencia/Cheque
                        </button>
                        {dats.idCliente != "" && dats.Credito == true ? (
                          <button
                            onClick={() => {
                              if (dats.Deuda + bolsaSub > dats.Limit) {
                                setErr(
                                  "Revasa Limite Credito, Deuda Actual Q" +
                                    dats.Deuda
                                )
                                setShow(true)
                                setTimeout(() => {
                                  setShow(false)
                                }, 3500)
                              } else {
                                setCred(1)
                              }
                            }}
                            className="cursor-pointer text-white bg-[#050708] focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-center hover:bg-[#050708]/40 focus:ring-gray-600 mr-2 mb-2"
                          >
                            Guardar Como Credito; Limite Credito: {dats.Limit}{" "}
                            Deuda: {dats.Deuda}
                          </button>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {cred == 2 ? (
                    <>
                      <div className="grid grid-cols-2 mt-2 ml-20 mx-4 gap-6">
                        <div className="relative z-0 w-full  group">
                          <input
                            autoComplete="off"
                            onChange={OnchangesPa}
                            name="NoDoc"
                            value={datP.NoDoc}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            No. Documento
                          </label>
                        </div>
                        <div className="relative z-0 w-full group">
                          <input
                            autoComplete="off"
                            name="Banco"
                            value={datP.Banco}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            onChange={OnchangesPa}
                          />
                          <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Banco
                          </label>
                        </div>

                        <div className="relative z-20 w-1/2 mb-6 ">
                          <label className="peer-focus:font-medium absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0  peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Fecha Documento {datP.FechaDoc}
                          </label>
                          <DatePicker
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            onSelect={(date) => {
                              setStartDate(date)
                              setDatP({
                                ...datP,
                                ["FechaDoc"]: moment(
                                  date.toLocaleDateString()
                                ).format("DD-MMM-yyyy"),
                              })
                            }}
                            className="block cursor-pointer z-20 text-gray-800 py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2  appearance-none border-gray-600  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          />
                        </div>

                        <div className="relative space-y-2 z-0 w-1/2 mb-6 group">
                          <label className="block text-black text-md font-bold mb-2">
                            Metodo De Pago
                          </label>
                          <div>
                            <select
                              onChange={(e) => {
                                setDatP({ ...datP, ["Tipo"]: e.target.value })
                              }}
                              className=" bg-transparent ml-1 text-md w-full"
                            >
                              <option className="text-sm text-indigo-800">
                                {" "}
                              </option>
                              <option
                                key={"Depostio"}
                                className="text-sm text-indigo-800"
                              >
                                Deposito
                              </option>
                              <option
                                key={"Cheque"}
                                className="text-sm text-indigo-800"
                              >
                                Cheque
                              </option>
                              <option
                                key={"Transferencia"}
                                className="text-sm text-indigo-800"
                              >
                                Transferencia
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="relative  space-y-2 z-0 w-1/2 mb-6 group">
                          <button
                            className="bg-blue-700  hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-md"
                            onClick={handle}
                          >
                            {file == null ? "Agregar Foto" : "Cambiar"}
                          </button>
                          <input
                            type="file"
                            autoComplete="off"
                            ref={hiddenFileInput}
                            onChange={handleChang}
                            accept="image/png, image/jpeg"
                            style={{ display: "none" }}
                          />
                        </div>

                        <div className="relative space-y-2 z-0 w-1/2 mb-6 group">
                          {file == null ? null : (
                            <>
                              <img
                                className="p-1 bg-white border rounded max-w-sm"
                                src={URL.createObjectURL(file)}
                              />
                            </>
                          )}
                        </div>

                        <button
                          className="bg-blue-700 z-10 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-md"
                          onClick={() => {
                            setCred(0)
                            window.scrollTo(0, 0)
                          }}
                        >
                          Atras
                        </button>
                        {datP.NoDoc != "" &&
                        datP.Banco != "" &&
                        datP.FechaDoc != "" &&
                        file != null ? (
                          <button
                            className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-md"
                            onClick={() => {
                              Guarda(false)
                            }}
                          >
                            Guardar
                          </button>
                        ) : null}
                      </div>
                    </>
                  ) : null}

                  {cred == 1 ? (
                    <div className="grid grid-cols-2 mt-2 ml-20 mx-4 gap-6">
                      <div className="relative z-0 w-1/2 mb-6 ">
                        <label className="peer-focus:font-medium absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0  peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                          Fecha Pago {dats.DiaPago}
                        </label>
                        <DatePicker
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          onSelect={(date) => {
                            setStartDate(date)
                            setDats({
                              ...dats,
                              ["DiaPago"]: moment(
                                date.toLocaleDateString()
                              ).format("DD-MMM-yyyy"),
                            })
                          }}
                          className="block cursor-pointer text-gray-800 py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2  appearance-none border-gray-600  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                      </div>

                      <button
                        className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-md"
                        onClick={() => {
                          setCred(0)
                        }}
                      >
                        Atras
                      </button>
                      {dats.DiaPago != "" ? (
                        <button
                          className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-md"
                          onClick={() => {
                            Guarda(true)
                          }}
                        >
                          Guardar
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}

              {creado == true ? (
                <div className="container ml-14">
                  <button
                    onClick={() => {
                      setVer(!ver)
                    }}
                  >
                    VER HIstorial
                  </button>
                  {ver == true ? (
                    <PDFViewer
                      style={{
                        width: "100%", //the pdf viewer will take up all of the width and height
                        height: window.innerHeight,
                      }}
                      className="pt-2 w-full"
                      fileName="Orden"
                    >
                      <Invoice lastSell={lastSell} />
                    </PDFViewer>
                  ) : null}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      )}
    </>
  )
}
