import React, { useEffect } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"
import mt from "../assets/png/logo.png"
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: "5px",
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  item: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 2,
  },
  subitem: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 400,
  },
  textL: {
    textAlign: "right",
    display: "flex",
    padding: 5,
    // width: '66%', //<- working alternative
    flexShrink: 1,
    flexGrow: 2,
    fontSize: "12px",
  },
  img: {
    justifyContent: "center",
    marginRight: "150px",
    height: "10%",
  },
  content: {
    marginTop: "15px",
    borderTop: "1px solid black",
  },
  env: {
    flexGrow: 0,
    marginTop: "15px",
    flexShrink: 1,
    marginLeft: "14px",
    justifyContent: "center",
    fontSize: "14px",
  },

  row: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  left: {
    // width: '33%',//<- working alternative
    flexGrow: 0,
    width: "67%",
    flexShrink: 1,
  },

  right: {
    width: "33%",
    padding: 5,
    flexShrink: 1,
  },
})

export default function Invoice({ lastSell }) {
  return (
    <Document title="Orden">
      <Page wrap={false} style={styles.page}>
        <View style={styles.section}>
          <View style={styles.item}>
            <View style={styles.subitem}>
              <Image style={styles.img} src={mt} />
            </View>
            <View style={styles.textL}>
              <Text style={{ padding: "1px" }}>
                Orden No: {lastSell.Venta.No}
              </Text>
              <Text style={{ padding: "1px" }}>{lastSell.Venta.Creado}</Text>
              {lastSell.Venta.Credito == true ? (
                <Text style={{ padding: "1px" }}>
                  Credito: {lastSell.Venta.DiaPago}
                </Text>
              ) : (
                <Text style={{ padding: "1px" }}>
                  {" "}
                  Pagado: {lastSell.Pago.Tipo}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.row}>
              <View style={styles.left}>
                <Text style={{ padding: "2px" }}>
                  {lastSell.Venta.Nombre} {lastSell.Venta.Numero}
                </Text>
                <Text style={{ padding: "2px" }}>
                  {lastSell.Venta.Direccion} {lastSell.Venta.Transporte}
                </Text>
              </View>
              {lastSell.Venta.Credito == false ? (
                <View style={styles.right}>
                  <Text style={{ padding: "2px" }}>
                    {lastSell.Pago.Banco} {lastSell.Pago.NoDoc}
                  </Text>
                  <Text style={{ padding: "2px" }}>
                    {lastSell.Pago.FechaDoc}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
          <View style={styles.content}>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                fontSize: "14px",
                marginTop: "16px",
                padding: "2px",
                border: "1px solid black",
                backgroundColor: "#eaf5ce",
              }}
            >
              <Text style={{ width: "18%" }}>Titulo</Text>
              <Text style={{ width: "25%" }}>Descripcion</Text>
              <Text style={{ width: "18%" }}>Cantidad</Text>
              <Text style={{ width: "18%" }}>Precio Unidad</Text>
              <Text style={{ width: "18%" }}>Prcio Total</Text>
            </View>
            {lastSell.Ventas.map((list) => {
              return (
                <View
                  key={list.id}
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "space-evenly",
                    fontSize: "14px",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text style={{ width: "18%" }}>{list.Titulo}</Text>
                  <Text style={{ width: "25%" }}>{list.Descripcion}</Text>
                  <Text style={{ width: "18%" }}>{list.Cantidad}</Text>
                  <Text style={{ width: "18%" }}>
                    Q{list.PrecioUnidad.toFixed(2)}
                  </Text>
                  <Text style={{ width: "18%" }}>
                    Q{list.PrecioTotal.toFixed(2)}
                  </Text>
                </View>
              )
            })}
          </View>
          <View
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "space-evenly",
              fontSize: "14px",
              marginTop: "6px",
              padding: "5px",
            }}
          >
            <Text>Total: Q{lastSell.Venta.Total.toFixed(2)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
