import React, { useEffect, useState } from "react"
import moment from "moment"
import { firestore, auth } from "../firebase/cliente"
import { doc, setDoc, onSnapshot, collection } from "firebase/firestore"
import Alert from "../Components/Alert"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth"
import Load from "../Components/Load"
import { useNavigate } from "react-router-dom"

const Register = () => {
  const [show, setShow] = useState(false)
  const [showLoad, setShowLoad] = useState(false)
  const [err, setErr] = useState("")
  const [selec, setSelec] = useState([])
  const [logMod, setLogMod] = useState(false)
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirm: "",
    nombre: "",
    apellido: "",
    numero: "",
    direccion: "",
  })
  const deps = [
    {
      Nombre: "Alta Verapaz",
      Municipios: [
        { Nombre: "Cobán" },
        { Nombre: "Santa Cruz Verapaz" },
        { Nombre: "San Cristóbal Verapaz" },
        { Nombre: "Tactic" },
        { Nombre: "Tamahú" },
        { Nombre: "San Miguel Tucurú" },
        { Nombre: "Panzóz" },
        { Nombre: "Senahú" },
        { Nombre: "San Pedro Carchá" },
        { Nombre: "San Juan Chamelco" },
        { Nombre: "San Agustín Lanquín" },
        { Nombre: "Santa María Cahabón" },
        { Nombre: "Chisec" },
        { Nombre: "Chahal" },
        { Nombre: "Fray Bartolomé de las Casas" },
        { Nombre: "Santa Catalina La Tinta" },
        { Nombre: "Raxruhá" },
      ],
    },
    {
      Nombre: "Baja Verapaz",
      Municipios: [
        { Nombre: "Salamá" },
        { Nombre: "San Miguel Chicaj" },
        { Nombre: "Rabinal" },
        { Nombre: "Cubulco" },
        { Nombre: "Granados" },
        { Nombre: "Santa Cruz el Chol" },
        { Nombre: "San Jerónimo" },
        { Nombre: "Purulhá" },
      ],
    },
    {
      Nombre: "Chimaltenago",
      Municipios: [
        { Nombre: "Chimaltenango" },
        { Nombre: "San José Poaquil" },
        { Nombre: "San Martín Jilotepeque" },
        { Nombre: "San Juan Comalapa" },
        { Nombre: "Santa Apolonia" },
        { Nombre: "Tecpán" },
        { Nombre: "Patzún" },
        { Nombre: "San Miguel Pochuta" },
        { Nombre: "Patzicía" },
        { Nombre: "Santa Cruz Balanyá" },
        { Nombre: "Acatenango" },
        { Nombre: "San Pedro Yepocapa" },
        { Nombre: "San Andrés Itzapa" },
        { Nombre: "Parramos" },
        { Nombre: "Zaragoza" },
        { Nombre: "El Tejar" },
      ],
    },
    {
      Nombre: "Chiquimula",
      Municipios: [
        { Nombre: "Chiquimula" },
        { Nombre: "Jocotán" },
        { Nombre: "Esquipulas" },
        { Nombre: "Camotán" },
        { Nombre: "Quezaltepeque" },
        { Nombre: "Olopa" },
        { Nombre: "Ipala" },
        { Nombre: "San Juan Ermita" },
        { Nombre: "Concepción Las Minas" },
        { Nombre: "San Jacinto" },
        { Nombre: "San José la Arada" },
      ],
    },
    {
      Nombre: "Guatemala",
      Municipios: [
        { Nombre: "Santa Catarina Pinula" },
        { Nombre: "San José Pinula" },
        { Nombre: "Guatemala" },
        { Nombre: "San José del Golfo" },
        { Nombre: "Palencia" },
        { Nombre: "Chinautla" },
        { Nombre: "San Pedro Ayampuc" },
        { Nombre: "Mixco" },
        { Nombre: "San Pedro Sacatapéquez" },
        { Nombre: "San Juan Sacatepéquez" },
        { Nombre: "Chuarrancho" },
        { Nombre: "San Raymundo" },
        { Nombre: "Fraijanes" },
        { Nombre: "Amatitlán" },
        { Nombre: "Villa Nueva" },
        { Nombre: "Villa Canales" },
        { Nombre: "San Miguel Petapa" },
      ],
    },
    {
      Nombre: "El Progreso",
      Municipios: [
        { Nombre: "El Jícaro" },
        { Nombre: "Morazán" },
        { Nombre: "San Agustín Acasaguastlán" },
        { Nombre: "San Antonio La Paz" },
        { Nombre: "San Cristóbal Acasaguastlán" },
        { Nombre: "Sanarate" },
        { Nombre: "Guastatoya" },
        { Nombre: "Sansare" },
      ],
    },
    {
      Nombre: "Escuintla",
      Municipios: [
        { Nombre: "Escuintla" },
        { Nombre: "Santa Lucía Cotzumalguapa" },
        { Nombre: "La Democracia" },
        { Nombre: "Siquinalá" },
        { Nombre: "Masagua" },
        { Nombre: "Tiquisate" },
        { Nombre: "La Gomera" },
        { Nombre: "Guaganazapa" },
        { Nombre: "San José" },
        { Nombre: "Iztapa" },
        { Nombre: "Palín" },
        { Nombre: "San Vicente Pacaya" },
        { Nombre: "Nueva Concepción" },
      ],
    },
    {
      Nombre: "Huehuetenango",
      Municipios: [
        { Nombre: "Huehuetenango" },
        { Nombre: "Chiantla" },
        { Nombre: "Malacatancito" },
        { Nombre: "Cuilco" },
        { Nombre: "Nentón" },
        { Nombre: "San Pedro Necta" },
        { Nombre: "Jacaltenango" },
        { Nombre: "Soloma" },
        { Nombre: "Ixtahuacán" },
        { Nombre: "Santa Bárbara" },
        { Nombre: "La Libertad" },
        { Nombre: "La Democracia" },
        { Nombre: "San Miguel Acatán" },
        { Nombre: "San Rafael La Independencia" },
        { Nombre: "Todos Santos Cuchumatán" },
        { Nombre: "San Juan Atitlán" },
        { Nombre: "Santa Eulalia" },
        { Nombre: "San Mateo Ixtatán" },
        { Nombre: "Colotenango" },
        { Nombre: "San Sebastián Huehuetenango" },
        { Nombre: "Tectitán" },
        { Nombre: "Concepción Huista" },
        { Nombre: "San Juan Ixcoy" },
        { Nombre: "San Antonio Huista" },
        { Nombre: "Santa Cruz Barillas" },
        { Nombre: "San Sebastián Coatán" },
        { Nombre: "Aguacatán" },
        { Nombre: "San Rafael Petzal" },
        { Nombre: "San Gaspar Ixchil" },
        { Nombre: "Santiago Chimaltenango" },
        { Nombre: "Santa Ana Huista" },
      ],
    },
    {
      Nombre: "Izabal",
      Municipios: [
        { Nombre: "Morales" },
        { Nombre: "Los Amates" },
        { Nombre: "Livingston" },
        { Nombre: "El Estor" },
        { Nombre: "Puerto Barrios" },
      ],
    },
    {
      Nombre: "Jalapa",
      Municipios: [
        { Nombre: "Jalapa" },
        { Nombre: "San Pedro Pinula" },
        { Nombre: "San Luis Jilotepeque" },
        { Nombre: "San Manuel Chaparrón" },
        { Nombre: "San Carlos Alzatate" },
        { Nombre: "Monjas" },
        { Nombre: "Mataquescuintla" },
      ],
    },
    {
      Nombre: "Jutiapa",
      Municipios: [
        { Nombre: "Jutiapa" },
        { Nombre: "El Progreso" },
        { Nombre: "Santa Catarina Mita" },
        { Nombre: "Agua Blanca" },
        { Nombre: "Asunción Mita" },
        { Nombre: "Yupiltepeque" },
        { Nombre: "Atescatempa" },
        { Nombre: "Jerez" },
        { Nombre: "El Adelanto" },
        { Nombre: "Zapotitlán" },
        { Nombre: "Comapa" },
        { Nombre: "Jalpatagua" },
        { Nombre: "Conguaco" },
        { Nombre: "Moyuta" },
        { Nombre: "Pasaco" },
        { Nombre: "Quesada" },
      ],
    },
    {
      Nombre: "Petén",
      Municipios: [
        { Nombre: "Flores" },
        { Nombre: "San José" },
        { Nombre: "San Benito" },
        { Nombre: "San Andrés" },
        { Nombre: "La Libertad" },
        { Nombre: "San Francisco" },
        { Nombre: "Santa Ana" },
        { Nombre: "Dolores" },
        { Nombre: "San Luis" },
        { Nombre: "Sayaxché" },
        { Nombre: "Melchor de Mencos" },
        { Nombre: "Poptún" },
      ],
    },
    {
      Nombre: "Quetzaltenango",
      Municipios: [
        { Nombre: "Quetzaltenango" },
        { Nombre: "Salcajá" },
        { Nombre: "San Juan Olintepeque" },
        { Nombre: "San Carlos Sija" },
        { Nombre: "Sibilia" },
        { Nombre: "Cabricán" },
        { Nombre: "Cajolá" },
        { Nombre: "San Miguel Siguilá" },
        { Nombre: "San Juan Ostuncalco" },
        { Nombre: "San Mateo" },
        { Nombre: "Concepción Chiquirichapa" },
        { Nombre: "San Martín Sacatepéquez" },
        { Nombre: "Almolonga" },
        { Nombre: "Cantel" },
        { Nombre: "Huitán" },
        { Nombre: "Zunil" },
        { Nombre: "San Francisco La Unión" },
        { Nombre: "El Palmar" },
        { Nombre: "Coatepeque" },
        { Nombre: "Génova" },
        { Nombre: "Flores Costa Cuca" },
        { Nombre: "La Esperanza" },
        { Nombre: "Palestina de Los Altos" },
      ],
    },
    {
      Nombre: "Quiché",
      Municipios: [
        { Nombre: "Santa Cruz del Quiché" },
        { Nombre: "Chiché" },
        { Nombre: "Chinique" },
        { Nombre: "Zacualpa" },
        { Nombre: "Chajul" },
        { Nombre: "Santo Tomás Chichicastenango" },
        { Nombre: "Patzité" },
        { Nombre: "San Antonio Ilotenango" },
        { Nombre: "San Pedro Jocopilas" },
        { Nombre: "Cunén" },
        { Nombre: "San Juan Cotzal" },
        { Nombre: "Santa María Joyabaj" },
        { Nombre: "Santa María Nebaj" },
        { Nombre: "San Andrés Sajcabajá" },
        { Nombre: "Uspantán" },
        { Nombre: "Sacapulas" },
        { Nombre: "San Bartolomé Jocotenango" },
        { Nombre: "Canillá" },
        { Nombre: "Chicamán" },
        { Nombre: "Playa Grande Ixcán" },
        { Nombre: "Pachalum" },
      ],
    },
    {
      Nombre: "Retalhuleu",
      Municipios: [
        { Nombre: "Retalhuleu" },
        { Nombre: "San Sebastián" },
        { Nombre: "Santa Cruz Muluá" },
        { Nombre: "San Martín Zapotitlán" },
        { Nombre: "San Felipe" },
        { Nombre: "San Andrés Villa Seca" },
        { Nombre: "Champerico" },
        { Nombre: "Nuevo San Carlos" },
        { Nombre: "El Asintal" },
      ],
    },
    {
      Nombre: "Sacatepequez",
      Municipios: [
        { Nombre: "Antigua Guatemala" },
        { Nombre: "Jocotenango" },
        { Nombre: "Pastores" },
        { Nombre: "Sumpango" },
        { Nombre: "Santo Domingo Xenacoj" },
        { Nombre: "Santiago Sacatepéquez" },
        { Nombre: "San Bartolomé Milpas Altas" },
        { Nombre: "San Lucas Sacatepéquez" },
        { Nombre: "Santa Lucía Milpas Altas" },
        { Nombre: "Magdalena Milpas Altas" },
        { Nombre: "Santa María de Jesús" },
        { Nombre: "Ciudad Vieja" },
        { Nombre: "San Miguel Dueñas" },
        { Nombre: "San Juan Alotenango" },
        { Nombre: "San Antonio Aguas Calientes" },
        { Nombre: "Santa Catarina Barahona" },
      ],
    },
    {
      Nombre: "San Marcos",
      Municipios: [
        { Nombre: "San Marcos" },
        { Nombre: "Ayutla" },
        { Nombre: "Catarina" },
        { Nombre: "Comitancillo" },
        { Nombre: "Concepción Tutuapa" },
        { Nombre: "El Quetzal" },
        { Nombre: "El Rodeo" },
        { Nombre: "El Tumblador" },
        { Nombre: "Ixchiguán" },
        { Nombre: "La Reforma" },
        { Nombre: "Malacatán" },
        { Nombre: "Nuevo Progreso" },
        { Nombre: "Ocós" },
        { Nombre: "Pajapita" },
        { Nombre: "Esquipulas Palo Gordo" },
        { Nombre: "San Antonio Sacatepéquez" },
        { Nombre: "San Cristóbal Cucho" },
        { Nombre: "San José Ojetenam" },
        { Nombre: "San Lorenzo" },
        { Nombre: "San Miguel Ixtahuacán" },
        { Nombre: "San Pablo" },
        { Nombre: "San Pedro Sacatepéquez" },
        { Nombre: "San Rafael Pie de la Cuesta" },
        { Nombre: "Sibinal" },
        { Nombre: "Sipacapa" },
        { Nombre: "Tacaná" },
        { Nombre: "Tajumulco" },
        { Nombre: "Tejutla" },
        { Nombre: "Río Blanco" },
        { Nombre: "La Blanca" },
      ],
    },
    {
      Nombre: "Santa Rosa",
      Municipios: [
        { Nombre: "Cuilapa" },
        { Nombre: "Casillas Santa Rosa" },
        { Nombre: "Chiquimulilla" },
        { Nombre: "Guazacapán" },
        { Nombre: "Nueva Santa Rosa" },
        { Nombre: "Oratorio" },
        { Nombre: "Pueblo Nuevo Viñas" },
        { Nombre: "San Juan Tecuaco" },
        { Nombre: "San Rafael Las Flores" },
        { Nombre: "Santa Cruz Naranjo" },
        { Nombre: "Santa María Ixhuatán" },
        { Nombre: "Santa Rosa de Lima" },
        { Nombre: "Taxisco" },
        { Nombre: "Barberena" },
      ],
    },
    {
      Nombre: "Solola",
      Municipios: [
        { Nombre: "Sololá" },
        { Nombre: "Concepción" },
        { Nombre: "Nahualá" },
        { Nombre: "Panajachel" },
        { Nombre: "San Andrés Semetabaj" },
        { Nombre: "San Antonio Palopó" },
        { Nombre: "San José Chacayá" },
        { Nombre: "San Juan La Laguna" },
        { Nombre: "San Lucas Tolimán" },
        { Nombre: "San Marcos La Laguna" },
        { Nombre: "San Pablo La Laguna" },
        { Nombre: "San Pedro La Laguna" },
        { Nombre: "Santa Catarina Ixtahuacán" },
        { Nombre: "Santa Catarina Palopó" },
        { Nombre: "Santa Clara La Laguna" },
        { Nombre: "Santa Cruz La Laguna" },
        { Nombre: "Santa Lucía Utatlán" },
        { Nombre: "Santa María Visitación" },
        { Nombre: "Santiago Atitlán" },
      ],
    },
    {
      Nombre: "Suchitepequez",
      Municipios: [
        { Nombre: "Mazatenango" },
        { Nombre: "Cuyotenango" },
        { Nombre: "San Francisco Zapotitlán" },
        { Nombre: "San Bernardino" },
        { Nombre: "San José El Ídolo" },
        { Nombre: "Santo Domingo Suchitépequez" },
        { Nombre: "San Lorenzo" },
        { Nombre: "Samayac" },
        { Nombre: "San Pablo Jocopilas" },
        { Nombre: "San Antonio Suchitépequez" },
        { Nombre: "San Miguel Panán" },
        { Nombre: "San Gabriel" },
        { Nombre: "Chicacao" },
        { Nombre: "Patulul" },
        { Nombre: "Santa Bárbara" },
        { Nombre: "San Juan Bautista" },
        { Nombre: "Santo Tomás La Unión" },
        { Nombre: "Zunilito" },
        { Nombre: "Pueblo Nuevo" },
        { Nombre: "Río Bravo" },
      ],
    },
    {
      Nombre: "Totonicapán",
      Municipios: [
        { Nombre: "Totonicapán" },
        { Nombre: "San Cristóbal Totonicapán" },
        { Nombre: "San Francisco El Alto" },
        { Nombre: "San Andrés Xecul" },
        { Nombre: "Momostenango" },
        { Nombre: "Santa María Chiquimula" },
        { Nombre: "Santa Lucía La Reforma" },
        { Nombre: "San Bartolo" },
      ],
    },
    {
      Nombre: "Zacapa",
      Municipios: [
        { Nombre: "Cabañas" },
        { Nombre: "Estanzuela" },
        { Nombre: "Gualán" },
        { Nombre: "Huité" },
        { Nombre: "La Unión" },
        { Nombre: "Río Hondo" },
        { Nombre: "San Jorge" },
        { Nombre: "San Diego" },
        { Nombre: "Teculután" },
        { Nombre: "Usumatlán" },
        { Nombre: "Zacapa" },
      ],
    },
  ]
  const route = useNavigate()
  const [dep, setDep] = useState("")
  const [muni, setMuni] = useState("")
  const Onchanges = ({ target: { name, value } }) => {
    const result = value.replace(/\D/g, "")
    if (name == "Numero") {
      if (isNaN(result)) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 3500)
        setErr("Error, Solo puede ingresar numero")
      } else {
        setUser({ ...user, [name]: result })
      }
    } else {
      setUser({ ...user, [name]: value })
    }
  }
  const set = async (event) => {
    const id = event.target.value
    setDep(id)
    deps.map((list) => {
      if (id == list.Nombre) {
        setSelec(list.Municipios)
      }
    })
  }
  const guardar = async () => {
    const Fecha = moment().format("DD-MMM-yyyy hh:mm:ss a")
    const add = {
      Nombre: user.nombre,
      Apellido: user.apellido,
      Numero: user.numero,
      Direccion: dep + " " + muni + " " + user.direccion,
      Correo: user.email,
      Creado: Fecha,
      Rango: "Cliente",
      Pas: user.password,
    }
    try {
      setShowLoad(true)
      window.scrollTo(0, 0)
      await createUserWithEmailAndPassword(auth, user.email, user.password)
      await setDoc(doc(firestore, "Usuarios", auth.currentUser.uid), add)
      setShow(!show)
      setTimeout(() => {
        setShow((show) => !show)
        setShowLoad(false)
      }, 3500)
      setErr("cuentra creada con exito")
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErr("El correo ya esta en uso")
      } else if (error.code === "auth/internal-error" && user.email === "") {
        setErr("Debe ingresar correo")
      } else if (
        error.code === "auth/internal-error" &&
        user.password.length < 6
      ) {
        setErr("Debe ingresar una contrase;a con ams de 6 caracteres")
      } else {
        setErr(error.code)
      }
      setShow(!show)
      setTimeout(() => {
        setShow((show) => !show)
      }, 3500)
    }
  }
  const logIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, user.email, user.password)
      setShow(!show)
      setTimeout(() => {
        setShow((show) => !show)
      }, 2500)
      setErr("Secion iniciada")
    } catch (e) {
      if (e.code == "auth/wrong-password") {
        setErr("Contraseña incorrecta")
      } else if (e.code == "auth/user-not-found") {
        setErr("Revise sus datos")
      } else if (e.code == "auth/invalid-email") {
        setErr("Email invalido, crea una cuenta")
      } else {
        setErr("Contacte con el encargado")
      }

      setShow(!show)
      setTimeout(() => {
        setShow((show) => !show)
      }, 3500)
    }
  }

  return (
    <div
      className="h-auto py-10"
      style={{ backgroundImage: `linear-gradient(115deg, #fff, #0B72F6)` }}
    >
      <Alert msg={err} show={show} />
      {showLoad == true ? (
        <Load />
      ) : (
        <div className="flex xs:flex-col flex-row w-10/12 xs:w-auto bg-white rounded-xl mx-auto shadow-lg  xs:ml-16 overflow-hidden ">
          <div
            className="xs:w-auto w-1/2 flex flex-col items-center justify-center xs:py-20 px-12 bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/mitiendaspa-7b291.appspot.com/o/bg.jpeg?alt=media&token=f541a0c1-c601-4932-ad7b-f1b5a4b01694')`,
            }}
          >
            <h1 className="text-white text-3xl mb-3">Bienbenido</h1>
            <div className="text-center">
              <span className="text-white">
                Una tienda de productos artesanales que entrega en Antigua
                Guatemala con envio inmediato
                <br />
                <div
                  onClick={() => {
                    setLogMod(!logMod)
                  }}
                  className="cursor-pointer text-gray-200  border-orange-200 text-center font-semibold"
                >
                  {logMod == false ? (
                    <span className="border-b">
                      Ya Tienes Cuenta inicia Sesion
                    </span>
                  ) : (
                    <span className="border-b">Eres Nuevo Crea Tu Cuenta</span>
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="xs:w-full w-1/2 py-16 px-12">
            {logMod == false ? (
              <>
                <h2 className="text-3xl mb-4">Nuevo Usuario</h2>
                <p className="mb-4">Crear Tu Cuenta, solo toma un momento</p>
                <div>
                  <div className="grid xs:grid-cols-1 grid-cols-2 gap-5">
                    <input
                      type="text"
                      autoComplete="off"
                      name="nombre"
                      value={user.nombre}
                      onChange={Onchanges}
                      placeholder="Nombre"
                      className="border border-gray-400 py-1 px-2"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      name="apellido"
                      value={user.apellido}
                      onChange={Onchanges}
                      placeholder="Apellido"
                      className="border border-gray-400 py-1 px-2"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      name="email"
                      value={user.email}
                      onChange={Onchanges}
                      placeholder="Correo"
                      className="border border-gray-400 py-1 px-2"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      name="numero"
                      value={user.numero}
                      onChange={Onchanges}
                      placeholder="Numero"
                      className="border border-gray-400 py-1 px-2"
                    />

                    <div className="">
                      <label
                        htmlFor="2"
                        className="block mb-2 text-sm font-medium text-black"
                      >
                        Selecciona Tu Departamento
                      </label>
                      <select
                        onChange={set}
                        id="departamentos"
                        className=" border text-sm rounded-lg block w-full p-2.5 bg-white  placeholder-gray-400 text-black ring-blue-500 border-blue-500"
                      >
                        <option key={" "}> </option>
                        {deps.map((list, i) => {
                          return <option key={i}>{list.Nombre}</option>
                        })}
                      </select>
                    </div>

                    {dep != "" ? (
                      <div>
                        <label
                          for="muni"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Selecciona Tu Municipio
                        </label>
                        <select
                          id="Municipio"
                          onChange={(e) => {
                            var munis = e.target.value
                            setMuni(munis)
                          }}
                          className=" border text-sm rounded-lg block w-full p-2.5 bg-white  placeholder-gray-400 text-black ring-blue-500 border-bue-500"
                        >
                          <option key={" "}> </option>
                          {selec.map((list) => {
                            return <option>{list.Nombre}</option>
                          })}
                        </select>
                      </div>
                    ) : null}
                    {dep != "" && muni != "" ? (
                      <div className="mt-5">
                        <input
                          type="direccion"
                          value={user.direccion}
                          onChange={Onchanges}
                          name="direccion"
                          placeholder="Completa Tu Direccion"
                          className="border border-gray-400 py-1 px-2 w-full"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-5 xs:grid-cols-1  gap-5 grid grid-cols-2">
                    <input
                      name="password"
                      type="password"
                      placeholder="Contra"
                      value={user.password}
                      onChange={Onchanges}
                      className="border border-gray-400 py-1 px-2 w-full"
                    />
                    <input
                      type="password"
                      name="confirm"
                      value={user.confirm}
                      onChange={Onchanges}
                      placeholder="Confirma tu contra"
                      className="border border-gray-400 py-1 px-2 w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <button
                      className="w-full bg-purple-500 py-3 text-center text-white"
                      onClick={() => {
                        if (
                          user.apellido == "" &&
                          user.nombre == "" &&
                          user.direccion == "" &&
                          user.numero == "" &&
                          user.password == "" &&
                          user.email == ""
                        ) {
                          setShow(true)
                          setErr("Debe ingresar todos los campos")
                          setTimeout(() => {
                            setShow((show) => !show)
                            setShowLoad(false)
                          }, 3500)
                        } else {
                          if (user.password != user.confirm) {
                            setShow(true)
                            setErr("Contrase;a no coincide")

                            setTimeout(() => {
                              setShow((show) => !show)
                              setShowLoad(false)
                            }, 3500)
                          } else {
                            guardar()
                          }
                        }
                      }}
                    >
                      Crea Tu Cuenta
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-3xl mb-4">Inicia Secion</h2>
                <p className="mb-4">Si no tienes una cuenta puedes crearla </p>
                <div>
                  <div className="grid xs:grid-cols-1 grid-cols-1 gap-5">
                    <input
                      type="text"
                      autoComplete="off"
                      name="email"
                      value={user.email}
                      onChange={Onchanges}
                      placeholder="Correo"
                      className="border border-gray-400 py-1 px-2"
                    />
                  </div>
                  <div className="mt-5 xs:grid-cols-1  gap-5 grid grid-cols-1">
                    <input
                      name="password"
                      type="password"
                      placeholder="Contra"
                      value={user.password}
                      onChange={Onchanges}
                      className="border border-gray-400 py-1 px-2 w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <button
                      className="w-full bg-purple-500 py-3 text-center text-white"
                      onClick={() => {
                        if (user.password == "" && user.email == "") {
                          setShow(true)
                          setErr("Debe ingresar todos los campos")
                          setTimeout(() => {
                            setShow((show) => !show)
                            setShowLoad(false)
                          }, 3500)
                        } else {
                          logIn()
                        }
                      }}
                    >
                      Inicia Secion
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Register
