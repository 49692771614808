import React from "react"
import { motion } from "framer-motion"
import { BsCheck2All } from "react-icons/bs"
import { staggerContainer, fadeIn } from "../options/variants"
import ModelWhiteImg from "../assets/png/model-white.png"
import MenuImg1 from "../assets/png/1.png"
import MenuImg2 from "../assets/png/2.png"
import MenuImg3 from "../assets/png/3.png"
import MenuImg4 from "../assets/png/4.png"
import { useAuth } from "../Components/authContext"

export default function MenuRandom() {
  const { stck, user } = useAuth()

  const cardProd = (list) => {
    return (
      <div key={list.id} className="w-full p-3">
        <a
          href=""
          className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden"
        >
          <div className="relative pb-48 overflow-hidden">
            <img
              className="absolute inset-0 h-full w-full object-contain hover:scale-125"
              src={list.Img}
              alt=""
            />
          </div>
          <div className="p-4">
            <span className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide text-xs">
              <h2 className="mt-2 mb-2  font-bold">{list.Titulo}</h2>
            </span>
            <p className="text-sm mt-4">{list.Descripcion}</p>
            <div className="mt-3 flex items-center justify-between">
              <span className="text-sm font-semibold">Precio</span>
              <span className="text-sm font-semibold">
                Q<span className="font-bold text-xl">{list.Precio}</span>
              </span>
            </div>
          </div>
          <div className="p-4 border-t border-b text-xs text-gray-700">
            <span className="flex items-center">
              <i className="text-lg text-green-400 mr-2">
                <BsCheck2All />
              </i>
              {list.Existencia != 0 ? "Disponible" : "Agotado"}
            </span>
          </div>
        </a>
      </div>
    )
  }

  return (
    <section className="min-h-[780px]">
      <div className="h-[780px] text-white bg-menu absolute w-full max-w-[1800px] -z-0"></div>

      <div className="relative z-10 top-72 xd:top-52">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.1 }}
          className="container mx-auto flex flex-col items-center text-center"
        >
          <motion.h2
            variants={fadeIn("down", "tween", 0.2, 1.6)}
            className="h2 capitalize text-white max-w-[400px] text-center"
          >
            Ya conocias estos prodcutos
          </motion.h2>
          <motion.p
            variants={fadeIn("down", "tween", 0.4, 1.6)}
            className="text-white/70 capitalize mb-8"
          >
            Lo mas recomendable en el mes
          </motion.p>

          <motion.div variants={fadeIn("down", "tween", 0.6, 1.6)}>
            <img src={ModelWhiteImg} alt="" />
          </motion.div>
        </motion.div>
      </div>
      <motion.div
        initial={{
          opacity: 0,
          y: 150,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          type: "tween",
          delay: 0.2,
          duration: 1.6,
          ease: "easeOut",
        }}
        className="relative top-80 ml-16 grid grid-cols-4 xs:grid-cols-1 gap-1"
      >
        {stck.map((list) => {
          return cardProd(list)
        })}
      </motion.div>
    </section>
  )
}
