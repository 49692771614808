import { getApps, initializeApp } from "firebase/app"
import "firebase/auth"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyDeupAB1TNS8voF6YJxfN40KIUQJLimBgI",
  authDomain: "mitiendaspa-7b291.firebaseapp.com",
  projectId: "mitiendaspa-7b291",
  storageBucket: "mitiendaspa-7b291.appspot.com",
  messagingSenderId: "960402844277",
  appId: "1:960402844277:web:e0e83fc73c597d04d4e63c",
  measurementId: "G-KQMFPQR1KW",
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
const firestore = getFirestore(app)
const storage = getStorage()
export { firestore, storage }
