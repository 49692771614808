import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { useAuth } from "../Components/authContext"
import { BsCheck2All } from "react-icons/bs"
import { useDropzone } from "react-dropzone"
import { Helmet } from "react-helmet"
import DatePicker from "react-datepicker"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { firestore, storage } from "../firebase/cliente"
import moment from "moment"
import Alert from "../Components/Alert"
import Load from "../Components/Load"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

export default function Productos() {
  const { stck, clases } = useAuth()
  const [search, setSearch] = useState("")
  const [imge, setImge] = useState("")
  const [err, setErr] = useState("")
  const [startDate, setStartDate] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [show, setShow] = useState(false)
  const [showLoad, setShowLoad] = useState(false)
  const [eliminar, setEliminar] = useState(false)
  const [select, setSelect] = useState({})
  const [files, setFiles] = useState([])
  const [stockSearch, setStockSearch] = useState([])

  const searchFilter = ({ target: { name, value } }) => {
    if (value) {
      const newData = stck.filter((item) => {
        const items = item.Titulo + item.Descripcion + item.Observacion //Advance Search
        const itemData = items ? items.toUpperCase() : "".toUpperCase()
        const textData = value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      setStockSearch(newData)
      setSearch(value)
    } else {
      setStockSearch(stck)
      setSearch(value)
    }
  }

  useEffect(() => {
    setStockSearch(stck)
  }, [stck])

  const geton = (id) => {
    const sellRef = doc(firestore, "Productos", id)
    var op = "desc"
    setShowModal(true)
    onSnapshot(sellRef, (doc) => {
      setSelect({ ...doc.data(), id: doc.id })
      setImge(doc.data().Img)
    })
  }
  const cardProd = (list) => {
    return (
      <>
        <div
          onClick={() => {
            geton(list.id)
          }}
          className="w-full p-3 xs:hidden"
        >
          <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
            <div className="relative pb-48 overflow-hidden">
              <img
                className="absolute inset-0 h-full w-full object-contain hover:scale-125"
                src={list.Img}
                alt=""
              />
            </div>
            <div className="p-4">
              <span className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide text-xs">
                <h2 className="mt-2 mb-2  font-bold">{list.Titulo}</h2>
              </span>
              <p className="text-sm mt-4">{list.Descripcion}</p>
              <div className="mt-3 flex items-center justify-between">
                <span className="text-sm font-semibold">Precio</span>
                <span className="text-sm font-semibold">
                  Q<span className="font-bold text-xl">{list.Precio}</span>
                </span>
              </div>
            </div>
            <div className="p-4 border-t border-b text-xs text-gray-700">
              <span className="flex items-center">
                <i className="text-lg text-green-400 mr-2">
                  <BsCheck2All />
                </i>
                {list.Existencia != 0 ? "Disponible" : "Agotado"}
              </span>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            geton(list.id)
          }}
          className="w-92 mr-1 mt-2 ml-1  card xd:hidden flex bg-base-100 shadow-xl"
        >
          <figure className="w-2/6 border border-red-200">
            <img src={list.Img} />
          </figure>
          <div className="mt-2 ml-20 space-y-14 ">
            <h2 className="font-bold text-xl">{list.Titulo}</h2>
            <p>{list.Descripcion}</p>
            <div className="card-actions justify-end">
              <button className="btn btn-primary">Q{list.Precio}</button>
            </div>
          </div>
        </div>
      </>
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 2,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const guardar = async () => {
    setShowLoad(true)
    const urls = []
    if (files != []) {
      for (let i = 1; i < 3; i++) {
        const d = i - 1
        const storageRef = ref(storage, `Productos/${select.Titulo}/${i}`)
        await uploadBytes(storageRef, files[d])
        const ul = await getDownloadURL(storageRef)
        urls.push(ul)
      }
    }

    var dtas = {
      ...select,
      Img: files[0] != null ? urls[0] : select.Img,
      Img2: files[1] != null ? urls[1] : select.Img2,
    }
    await updateDoc(doc(firestore, "Productos", dtas.id), dtas)
    geton(dtas.id)

    setShow(true)
    setErr("Documento Actualizado")
    setTimeout(() => {
      setShow(false)
      setShowLoad(false)
      setEdit(false)
      setFiles([])
    }, 3500)
  }

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ))
  const Onchanges = ({ target: { name, value } }) => {
    const result = value.replace(/\D/g, ".")
    if (name == "Precio" || name == "Costo" || name == "Existencia") {
      if (isNaN(result)) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 3500)
        setErr("Error, Solo puede ingresar numero")
      } else {
        setSelect({ ...select, [name]: result })
      }
    } else {
      setSelect({ ...select, [name]: value })
    }
  }
  const delet = async () => {
    setShow(true)
    setShowLoad(true)
    setErr("Documento Eliminado")
    await deleteDoc(doc(firestore, "Productos", select.id))
    setSelect({})
    setTimeout(() => {
      setShow(false)
      setShowModal(false)
      setShowLoad(false)
      setEliminar(false)
      setEdit(false)
    }, 3500)
  }

  return (
    <>
      <Alert show={show} msg={err} />
      {showLoad == true ? (
        <Load />
      ) : (
        <>
          {showModal == false ? (
            <>
              <div className="mx-20  relative pt-8">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Que Buscas?
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="search"
                    autoComplete="off"
                    value={search}
                    onChange={searchFilter}
                    id="default-search"
                    className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Mockups, Logos..."
                    required
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="relative ml-16 grid grid-cols-4 xs:grid-cols-1 gap-1">
                {stockSearch.map((list) => {
                  return cardProd(list)
                })}
              </div>
            </>
          ) : (
            <div className=" pt-12 pb-24 bg-blueGray-100 rounded-b-10xl overflow-hidden xd:ml-16">
              <div className="xd:container xs:pl-20 xs:w-full px-4 ">
                {edit == false ? (
                  <>
                    <div className="w-full space-x-10  justify-between grid grid-cols-2 px-2">
                      <ul className=" items-center flex w-full mb-8">
                        <li className="mr-6">
                          <div className="flex items-center  text-sm font-medium text-gray-400 hover:text-gray-500">
                            <span>Productos</span>
                            <svg
                              className="ml-6"
                              width="4"
                              height="7"
                              viewbox="0 0 4 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.150291 0.898704C-0.0500975 0.692525 -0.0500975 0.359292 0.150291 0.154634C0.35068 -0.0507836 0.674443 -0.0523053 0.874831 0.154634L3.7386 3.12787C3.93899 3.33329 3.93899 3.66576 3.7386 3.8727L0.874832 6.84594C0.675191 7.05135 0.35068 7.05135 0.150292 6.84594C-0.0500972 6.63976 -0.0500972 6.30652 0.150292 6.10187L2.49888 3.49914L0.150291 0.898704Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </li>
                        <li className="mr-6">
                          <a
                            className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-500"
                            href="#"
                          >
                            <span>{select.Clasificacion}</span>
                            <svg
                              className="ml-6"
                              width="4"
                              height="7"
                              viewbox="0 0 4 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.150291 0.898704C-0.0500975 0.692525 -0.0500975 0.359292 0.150291 0.154634C0.35068 -0.0507836 0.674443 -0.0523053 0.874831 0.154634L3.7386 3.12787C3.93899 3.33329 3.93899 3.66576 3.7386 3.8727L0.874832 6.84594C0.675191 7.05135 0.35068 7.05135 0.150292 6.84594C-0.0500972 6.63976 -0.0500972 6.30652 0.150292 6.10187L2.49888 3.49914L0.150291 0.898704Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li className="mr-6">
                          <p
                            className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                            href="#"
                          >
                            {select.Titulo}
                          </p>
                        </li>
                      </ul>
                      <h1
                        className="cursor-pointer text-red-700 "
                        onClick={() => {
                          setSelect({})
                          setShowModal(false)
                        }}
                      >
                        x
                      </h1>
                    </div>
                    <div className=" grid grid-cols-2 -mx-4 justify-between xs:grid-cols-1 xs:mx-10 content-start items-start">
                      <div className=" w-full px-2 xs:mb-16 mb-0">
                        <div className="flex -mx-4 pl-10 flex-cols xs:items-center xs:justify-between justify-start items-start xs:grid xs:grids-cols-rows ">
                          <div className="w-auto min-w-max px-4 text-center flex xd:flex-col xs:hidden items-center justify-center">
                            <div
                              onClick={() => {
                                setImge(select.Img)
                              }}
                              className="inline-block mb-12 xs:mr-4 mr-0 transform -rotate-90 sm:transform-none hover:text-darkBlueGray-400"
                            >
                              <svg
                                width="12"
                                height="8"
                                viewbox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.54064 7.21015C1.18719 7.59662 0.615928 7.59662 0.265087 7.21015C-0.087058 6.82369 -0.0896663 6.19929 0.265087 5.81282L5.36206 0.289847C5.71421 -0.0966173 6.28416 -0.0966172 6.63892 0.289847L11.7359 5.81282C12.088 6.19785 12.088 6.82369 11.7359 7.21015C11.3824 7.59662 10.8112 7.59662 10.4603 7.21015L5.99853 2.68073L1.54064 7.21015Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div
                              className="h-30 block mb-4 xs:mr-2 mr-0"
                              onMouseMove={() => {
                                setImge(select.Img)
                              }}
                            >
                              <img
                                className="h-24 w-24 object-cover"
                                src={select.Img}
                                alt="/"
                              />
                            </div>
                            <div
                              className="h-30 block mb-4 sm:mb-12 mr-4 sm:mr-0"
                              onMouseMove={() => {
                                setImge(select.Img2)
                              }}
                            >
                              <img
                                className="h-24 w-24 object-cover"
                                src={select.Img2}
                                alt=""
                              />
                            </div>
                            <div
                              onClick={() => {
                                setImge(select.Img2)
                              }}
                              className="inline-block mt-6 xs:mr-4 mr-0 transform -rotate-90 sm:transform-none hover:text-darkBlueGray-400"
                            >
                              <svg
                                width="12"
                                height="8"
                                viewbox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.4594 0.289849C10.8128 -0.0966154 11.3841 -0.0966154 11.7349 0.289849C12.0871 0.676313 12.0897 1.30071 11.7349 1.68718L6.63794 7.21015C6.28579 7.59662 5.71584 7.59662 5.36108 7.21015L0.264109 1.68718C-0.0880364 1.30215 -0.0880363 0.676312 0.264109 0.289848C0.617558 -0.096616 1.18882 -0.0966159 1.53966 0.289848L6.00147 4.81927L10.4594 0.289849Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <TransformWrapper>
                            <TransformComponent>
                              <div className="w-full">
                                <img
                                  className=" xs:w-4/6 xd:h-5/6 contain px-4 "
                                  src={imge}
                                  alt=""
                                />
                              </div>
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      </div>
                      <div className="xs:w-full mx-8 px-2">
                        <div className="max-w-md mb-6 ">
                          <h2 className="mt-6 mb-4 text-5xl md:text-7xl lg:text-8xl font-heading font-medium">
                            {select.Titulo}
                          </h2>
                          <p className="flex items-center mb-6">
                            <span className="mr-2 text-sm text-blue-500 font-medium">
                              Q
                            </span>
                            <span className="text-3xl text-blue-500 font-medium">
                              {select.Precio}
                            </span>
                          </p>
                          <p className="text-lg text-gray-400">
                            {select.Descripcion}
                          </p>
                        </div>
                        <div className="mb-10 ">
                          <h4 className="mb-3 font-heading font-medium">
                            Existencia:
                          </h4>
                          <input
                            className="w-24 px-3 py-2 text-center bg-white border-2 border-blue-500 outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
                            value={"" + select.Existencia}
                            type="text"
                            placeholder="1"
                          />
                        </div>
                        <div className="flex flex-wrap -mx-2 mb-12">
                          <div className="xs:w-2/3 w-1/2 px-2 xs:mb-0 mb-2">
                            <div className="block py-4 px-2 leading-8 font-heading font-medium tracking-tighter text-xl text-white text-center bg-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 hover:bg-blue-600 rounded-xl">
                              Agregar al carrito
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {eliminar == true ? (
                      <>
                        <div className="space-y-6  bg-white px-4 xs:mr-10 py-5 ">
                          <p className="font-bold text-black text-center">
                            Seguro De Eliminar: {select.Titulo}
                          </p>
                          <div className="bg-gray-50 space-x-10 px-4 py-3 text-center sm:px-6 cursor-pointer">
                            <div
                              onClick={(e) => {
                                setEliminar(false)
                              }}
                              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              No
                            </div>

                            <div
                              onClick={(e) => {
                                delet()
                              }}
                              className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                              Si
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="space-y-6  bg-white px-4 py-5 xs:mr-10 xs:p-6">
                        <div className="grid grid-cols-2  xs:grid-cols-1 w-full  gap-4">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              name="Titulo"
                              value={select.Titulo}
                              autoComplete="off"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              onChange={Onchanges}
                              required
                            />
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Titulo *
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              name="Precio"
                              autoComplete="off"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              value={"" + select.Precio}
                              onChange={Onchanges}
                              required
                            />
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Precio *
                            </label>
                          </div>
                          <div className="relative z-0  w-full mb-6 group">
                            <input
                              name="Existencia"
                              value={"" + select.Existencia}
                              autoComplete="off"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              onChange={Onchanges}
                              required
                            />
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Existencia *
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              name="Costo"
                              value={"" + select.Costo}
                              autoComplete="off"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              onChange={Onchanges}
                              required
                            />
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Costo *
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <select
                              onChange={(e) => {
                                setSelect({
                                  ...select,
                                  ["Clasificacion"]: e.target.value,
                                })
                              }}
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                              <option value=" " key={" "}>
                                {" "}
                              </option>
                              {clases.map((list) => {
                                return (
                                  <option
                                    key={list.Nombre}
                                    className="text-sm text-indigo-800"
                                  >
                                    {list.Nombre}
                                  </option>
                                )
                              })}
                            </select>
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Clasificacion seleccionada: {select.Clasificacion}
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              name="Observacion"
                              value={select.Observacion}
                              autoComplete="off"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              onChange={Onchanges}
                              required
                            />
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Observacion
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              name="Ubicacion"
                              value={select.Ubicacion}
                              autoComplete="off"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              onChange={Onchanges}
                              required
                            />
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Ubicacion
                            </label>
                          </div>
                          <div className="relative z-20 w-full mb-6 group">
                            <label
                              htmlFor="floating_email"
                              className="peer-focus:font-medium absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0  peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Fecha Aproximada De Vencimiento: {select.FechaEx}
                            </label>
                            <DatePicker
                              selected={startDate}
                              dateFormat="dd/MM/yyyy"
                              onSelect={(date) => {
                                setStartDate(date)
                                setSelect({
                                  ...select,
                                  FechaEx: moment(
                                    date.toLocaleDateString()
                                  ).format("DD-MMM-yyyy"),
                                })
                              }}
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            />
                          </div>
                        </div>
                        <div className="relative  z-0 w-full mb-6 group">
                          <textarea
                            name="Descripcion"
                            className="block border rounded-sm border-blue-500  rounded-smpy-2.5 px-1.5 w-full text-sm text-gray-900 bg-transparent border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            onChange={Onchanges}
                            required
                          ></textarea>
                          <label
                            htmlFor="floating_email"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 ml-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Descripcion
                          </label>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Agrega Fotos De Tus Productos
                          </label>

                          <section className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                            <div
                              className="space-y-1 text-center"
                              {...getRootProps({ className: "dropzone" })}
                            >
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                  <span>
                                    Seleciona Imagenes PNG, JPG, Maximo 2
                                    imagenes
                                  </span>
                                  <input
                                    {...getInputProps()}
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                  />
                                </label>
                              </div>
                              <aside style={thumbsContainer}>{thumbs}</aside>
                            </div>
                          </section>
                        </div>
                      </div>
                    )}
                    <div className="bg-gray-50 space-x-10 px-4 py-3 text-right xs:px-6 xs:mr-10 cursor-pointer">
                      <div
                        onClick={(e) => {
                          if (
                            select.Costo != "" &&
                            select.Precio != "" &&
                            select.Existencia != "" &&
                            select.Titulo != ""
                          ) {
                            guardar()
                          } else {
                            setErr("Debe ingresar todos los campos con *")
                            setShow(true)
                            setTimeout(() => {
                              setShow(false)
                            }, 3500)
                          }
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Guardar Cambios
                      </div>

                      <div
                        onClick={(e) => {
                          setEliminar(true)
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        Eliminar
                      </div>
                      <div
                        onClick={(e) => {
                          setEdit(false)
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Cancelar
                      </div>
                    </div>
                  </>
                )}
                <div className="w-full  px-2">
                  <div className="flex w-full py-4 px-2 items-center justify-center leading-8 font-heading font-medium tracking-tighter text-xl text-center focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50 hover:bg-opacity-60 rounded-xl">
                    <button
                      onClick={() => {
                        setEdit(true)
                      }}
                      className="bg-green-400 hover:bg-gray-400 text-gray-800 font-bold py-2 border-r border-black px-4 rounded-l"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => {
                        setEdit(false)
                      }}
                      className="bg-green-400 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                    >
                      Ver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
}

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
}

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
}

const img = {
  display: "block",
  width: "auto",
  height: "100%",
}
