import { signOut } from "firebase/auth"
import React, { useState, useEffect } from "react"
import { useAuth } from "../Components/authContext"
import { useNavigate } from "react-router-dom"
import { auth, firestore } from "../firebase/cliente"
import { doc, updateDoc } from "firebase/firestore"
import Alert from "../Components/Alert"

export default function UsPage() {
  const route = useNavigate()
  const { user } = useAuth()
  const [edit, setEdit] = useState(false)
  const [err, setErr] = useState("")
  const [show, setShow] = useState(false)
  const [dire, setDire] = useState("")
  const [selec, setSelec] = useState([])
  const [dep, setDep] = useState("")
  const [muni, setMuni] = useState("")

  const logut = () => {
    signOut(auth).then((e) => {
      route("/Register")
      window.scrollTo(0, 0)
    })
  }

  const deps = [
    {
      Nombre: "Alta Verapaz",
      Municipios: [
        { Nombre: "Cobán" },
        { Nombre: "Santa Cruz Verapaz" },
        { Nombre: "San Cristóbal Verapaz" },
        { Nombre: "Tactic" },
        { Nombre: "Tamahú" },
        { Nombre: "San Miguel Tucurú" },
        { Nombre: "Panzóz" },
        { Nombre: "Senahú" },
        { Nombre: "San Pedro Carchá" },
        { Nombre: "San Juan Chamelco" },
        { Nombre: "San Agustín Lanquín" },
        { Nombre: "Santa María Cahabón" },
        { Nombre: "Chisec" },
        { Nombre: "Chahal" },
        { Nombre: "Fray Bartolomé de las Casas" },
        { Nombre: "Santa Catalina La Tinta" },
        { Nombre: "Raxruhá" },
      ],
    },
    {
      Nombre: "Baja Verapaz",
      Municipios: [
        { Nombre: "Salamá" },
        { Nombre: "San Miguel Chicaj" },
        { Nombre: "Rabinal" },
        { Nombre: "Cubulco" },
        { Nombre: "Granados" },
        { Nombre: "Santa Cruz el Chol" },
        { Nombre: "San Jerónimo" },
        { Nombre: "Purulhá" },
      ],
    },
    {
      Nombre: "Chimaltenago",
      Municipios: [
        { Nombre: "Chimaltenango" },
        { Nombre: "San José Poaquil" },
        { Nombre: "San Martín Jilotepeque" },
        { Nombre: "San Juan Comalapa" },
        { Nombre: "Santa Apolonia" },
        { Nombre: "Tecpán" },
        { Nombre: "Patzún" },
        { Nombre: "San Miguel Pochuta" },
        { Nombre: "Patzicía" },
        { Nombre: "Santa Cruz Balanyá" },
        { Nombre: "Acatenango" },
        { Nombre: "San Pedro Yepocapa" },
        { Nombre: "San Andrés Itzapa" },
        { Nombre: "Parramos" },
        { Nombre: "Zaragoza" },
        { Nombre: "El Tejar" },
      ],
    },
    {
      Nombre: "Chiquimula",
      Municipios: [
        { Nombre: "Chiquimula" },
        { Nombre: "Jocotán" },
        { Nombre: "Esquipulas" },
        { Nombre: "Camotán" },
        { Nombre: "Quezaltepeque" },
        { Nombre: "Olopa" },
        { Nombre: "Ipala" },
        { Nombre: "San Juan Ermita" },
        { Nombre: "Concepción Las Minas" },
        { Nombre: "San Jacinto" },
        { Nombre: "San José la Arada" },
      ],
    },
    {
      Nombre: "Guatemala",
      Municipios: [
        { Nombre: "Santa Catarina Pinula" },
        { Nombre: "San José Pinula" },
        { Nombre: "Guatemala" },
        { Nombre: "San José del Golfo" },
        { Nombre: "Palencia" },
        { Nombre: "Chinautla" },
        { Nombre: "San Pedro Ayampuc" },
        { Nombre: "Mixco" },
        { Nombre: "San Pedro Sacatapéquez" },
        { Nombre: "San Juan Sacatepéquez" },
        { Nombre: "Chuarrancho" },
        { Nombre: "San Raymundo" },
        { Nombre: "Fraijanes" },
        { Nombre: "Amatitlán" },
        { Nombre: "Villa Nueva" },
        { Nombre: "Villa Canales" },
        { Nombre: "San Miguel Petapa" },
      ],
    },
    {
      Nombre: "El Progreso",
      Municipios: [
        { Nombre: "El Jícaro" },
        { Nombre: "Morazán" },
        { Nombre: "San Agustín Acasaguastlán" },
        { Nombre: "San Antonio La Paz" },
        { Nombre: "San Cristóbal Acasaguastlán" },
        { Nombre: "Sanarate" },
        { Nombre: "Guastatoya" },
        { Nombre: "Sansare" },
      ],
    },
    {
      Nombre: "Escuintla",
      Municipios: [
        { Nombre: "Escuintla" },
        { Nombre: "Santa Lucía Cotzumalguapa" },
        { Nombre: "La Democracia" },
        { Nombre: "Siquinalá" },
        { Nombre: "Masagua" },
        { Nombre: "Tiquisate" },
        { Nombre: "La Gomera" },
        { Nombre: "Guaganazapa" },
        { Nombre: "San José" },
        { Nombre: "Iztapa" },
        { Nombre: "Palín" },
        { Nombre: "San Vicente Pacaya" },
        { Nombre: "Nueva Concepción" },
      ],
    },
    {
      Nombre: "Huehuetenango",
      Municipios: [
        { Nombre: "Huehuetenango" },
        { Nombre: "Chiantla" },
        { Nombre: "Malacatancito" },
        { Nombre: "Cuilco" },
        { Nombre: "Nentón" },
        { Nombre: "San Pedro Necta" },
        { Nombre: "Jacaltenango" },
        { Nombre: "Soloma" },
        { Nombre: "Ixtahuacán" },
        { Nombre: "Santa Bárbara" },
        { Nombre: "La Libertad" },
        { Nombre: "La Democracia" },
        { Nombre: "San Miguel Acatán" },
        { Nombre: "San Rafael La Independencia" },
        { Nombre: "Todos Santos Cuchumatán" },
        { Nombre: "San Juan Atitlán" },
        { Nombre: "Santa Eulalia" },
        { Nombre: "San Mateo Ixtatán" },
        { Nombre: "Colotenango" },
        { Nombre: "San Sebastián Huehuetenango" },
        { Nombre: "Tectitán" },
        { Nombre: "Concepción Huista" },
        { Nombre: "San Juan Ixcoy" },
        { Nombre: "San Antonio Huista" },
        { Nombre: "Santa Cruz Barillas" },
        { Nombre: "San Sebastián Coatán" },
        { Nombre: "Aguacatán" },
        { Nombre: "San Rafael Petzal" },
        { Nombre: "San Gaspar Ixchil" },
        { Nombre: "Santiago Chimaltenango" },
        { Nombre: "Santa Ana Huista" },
      ],
    },
    {
      Nombre: "Izabal",
      Municipios: [
        { Nombre: "Morales" },
        { Nombre: "Los Amates" },
        { Nombre: "Livingston" },
        { Nombre: "El Estor" },
        { Nombre: "Puerto Barrios" },
      ],
    },
    {
      Nombre: "Jalapa",
      Municipios: [
        { Nombre: "Jalapa" },
        { Nombre: "San Pedro Pinula" },
        { Nombre: "San Luis Jilotepeque" },
        { Nombre: "San Manuel Chaparrón" },
        { Nombre: "San Carlos Alzatate" },
        { Nombre: "Monjas" },
        { Nombre: "Mataquescuintla" },
      ],
    },
    {
      Nombre: "Jutiapa",
      Municipios: [
        { Nombre: "Jutiapa" },
        { Nombre: "El Progreso" },
        { Nombre: "Santa Catarina Mita" },
        { Nombre: "Agua Blanca" },
        { Nombre: "Asunción Mita" },
        { Nombre: "Yupiltepeque" },
        { Nombre: "Atescatempa" },
        { Nombre: "Jerez" },
        { Nombre: "El Adelanto" },
        { Nombre: "Zapotitlán" },
        { Nombre: "Comapa" },
        { Nombre: "Jalpatagua" },
        { Nombre: "Conguaco" },
        { Nombre: "Moyuta" },
        { Nombre: "Pasaco" },
        { Nombre: "Quesada" },
      ],
    },
    {
      Nombre: "Petén",
      Municipios: [
        { Nombre: "Flores" },
        { Nombre: "San José" },
        { Nombre: "San Benito" },
        { Nombre: "San Andrés" },
        { Nombre: "La Libertad" },
        { Nombre: "San Francisco" },
        { Nombre: "Santa Ana" },
        { Nombre: "Dolores" },
        { Nombre: "San Luis" },
        { Nombre: "Sayaxché" },
        { Nombre: "Melchor de Mencos" },
        { Nombre: "Poptún" },
      ],
    },
    {
      Nombre: "Quetzaltenango",
      Municipios: [
        { Nombre: "Quetzaltenango" },
        { Nombre: "Salcajá" },
        { Nombre: "San Juan Olintepeque" },
        { Nombre: "San Carlos Sija" },
        { Nombre: "Sibilia" },
        { Nombre: "Cabricán" },
        { Nombre: "Cajolá" },
        { Nombre: "San Miguel Siguilá" },
        { Nombre: "San Juan Ostuncalco" },
        { Nombre: "San Mateo" },
        { Nombre: "Concepción Chiquirichapa" },
        { Nombre: "San Martín Sacatepéquez" },
        { Nombre: "Almolonga" },
        { Nombre: "Cantel" },
        { Nombre: "Huitán" },
        { Nombre: "Zunil" },
        { Nombre: "San Francisco La Unión" },
        { Nombre: "El Palmar" },
        { Nombre: "Coatepeque" },
        { Nombre: "Génova" },
        { Nombre: "Flores Costa Cuca" },
        { Nombre: "La Esperanza" },
        { Nombre: "Palestina de Los Altos" },
      ],
    },
    {
      Nombre: "Quiché",
      Municipios: [
        { Nombre: "Santa Cruz del Quiché" },
        { Nombre: "Chiché" },
        { Nombre: "Chinique" },
        { Nombre: "Zacualpa" },
        { Nombre: "Chajul" },
        { Nombre: "Santo Tomás Chichicastenango" },
        { Nombre: "Patzité" },
        { Nombre: "San Antonio Ilotenango" },
        { Nombre: "San Pedro Jocopilas" },
        { Nombre: "Cunén" },
        { Nombre: "San Juan Cotzal" },
        { Nombre: "Santa María Joyabaj" },
        { Nombre: "Santa María Nebaj" },
        { Nombre: "San Andrés Sajcabajá" },
        { Nombre: "Uspantán" },
        { Nombre: "Sacapulas" },
        { Nombre: "San Bartolomé Jocotenango" },
        { Nombre: "Canillá" },
        { Nombre: "Chicamán" },
        { Nombre: "Playa Grande Ixcán" },
        { Nombre: "Pachalum" },
      ],
    },
    {
      Nombre: "Retalhuleu",
      Municipios: [
        { Nombre: "Retalhuleu" },
        { Nombre: "San Sebastián" },
        { Nombre: "Santa Cruz Muluá" },
        { Nombre: "San Martín Zapotitlán" },
        { Nombre: "San Felipe" },
        { Nombre: "San Andrés Villa Seca" },
        { Nombre: "Champerico" },
        { Nombre: "Nuevo San Carlos" },
        { Nombre: "El Asintal" },
      ],
    },
    {
      Nombre: "Sacatepequez",
      Municipios: [
        { Nombre: "Antigua Guatemala" },
        { Nombre: "Jocotenango" },
        { Nombre: "Pastores" },
        { Nombre: "Sumpango" },
        { Nombre: "Santo Domingo Xenacoj" },
        { Nombre: "Santiago Sacatepéquez" },
        { Nombre: "San Bartolomé Milpas Altas" },
        { Nombre: "San Lucas Sacatepéquez" },
        { Nombre: "Santa Lucía Milpas Altas" },
        { Nombre: "Magdalena Milpas Altas" },
        { Nombre: "Santa María de Jesús" },
        { Nombre: "Ciudad Vieja" },
        { Nombre: "San Miguel Dueñas" },
        { Nombre: "San Juan Alotenango" },
        { Nombre: "San Antonio Aguas Calientes" },
        { Nombre: "Santa Catarina Barahona" },
      ],
    },
    {
      Nombre: "San Marcos",
      Municipios: [
        { Nombre: "San Marcos" },
        { Nombre: "Ayutla" },
        { Nombre: "Catarina" },
        { Nombre: "Comitancillo" },
        { Nombre: "Concepción Tutuapa" },
        { Nombre: "El Quetzal" },
        { Nombre: "El Rodeo" },
        { Nombre: "El Tumblador" },
        { Nombre: "Ixchiguán" },
        { Nombre: "La Reforma" },
        { Nombre: "Malacatán" },
        { Nombre: "Nuevo Progreso" },
        { Nombre: "Ocós" },
        { Nombre: "Pajapita" },
        { Nombre: "Esquipulas Palo Gordo" },
        { Nombre: "San Antonio Sacatepéquez" },
        { Nombre: "San Cristóbal Cucho" },
        { Nombre: "San José Ojetenam" },
        { Nombre: "San Lorenzo" },
        { Nombre: "San Miguel Ixtahuacán" },
        { Nombre: "San Pablo" },
        { Nombre: "San Pedro Sacatepéquez" },
        { Nombre: "San Rafael Pie de la Cuesta" },
        { Nombre: "Sibinal" },
        { Nombre: "Sipacapa" },
        { Nombre: "Tacaná" },
        { Nombre: "Tajumulco" },
        { Nombre: "Tejutla" },
        { Nombre: "Río Blanco" },
        { Nombre: "La Blanca" },
      ],
    },
    {
      Nombre: "Santa Rosa",
      Municipios: [
        { Nombre: "Cuilapa" },
        { Nombre: "Casillas Santa Rosa" },
        { Nombre: "Chiquimulilla" },
        { Nombre: "Guazacapán" },
        { Nombre: "Nueva Santa Rosa" },
        { Nombre: "Oratorio" },
        { Nombre: "Pueblo Nuevo Viñas" },
        { Nombre: "San Juan Tecuaco" },
        { Nombre: "San Rafael Las Flores" },
        { Nombre: "Santa Cruz Naranjo" },
        { Nombre: "Santa María Ixhuatán" },
        { Nombre: "Santa Rosa de Lima" },
        { Nombre: "Taxisco" },
        { Nombre: "Barberena" },
      ],
    },
    {
      Nombre: "Solola",
      Municipios: [
        { Nombre: "Sololá" },
        { Nombre: "Concepción" },
        { Nombre: "Nahualá" },
        { Nombre: "Panajachel" },
        { Nombre: "San Andrés Semetabaj" },
        { Nombre: "San Antonio Palopó" },
        { Nombre: "San José Chacayá" },
        { Nombre: "San Juan La Laguna" },
        { Nombre: "San Lucas Tolimán" },
        { Nombre: "San Marcos La Laguna" },
        { Nombre: "San Pablo La Laguna" },
        { Nombre: "San Pedro La Laguna" },
        { Nombre: "Santa Catarina Ixtahuacán" },
        { Nombre: "Santa Catarina Palopó" },
        { Nombre: "Santa Clara La Laguna" },
        { Nombre: "Santa Cruz La Laguna" },
        { Nombre: "Santa Lucía Utatlán" },
        { Nombre: "Santa María Visitación" },
        { Nombre: "Santiago Atitlán" },
      ],
    },
    {
      Nombre: "Suchitepequez",
      Municipios: [
        { Nombre: "Mazatenango" },
        { Nombre: "Cuyotenango" },
        { Nombre: "San Francisco Zapotitlán" },
        { Nombre: "San Bernardino" },
        { Nombre: "San José El Ídolo" },
        { Nombre: "Santo Domingo Suchitépequez" },
        { Nombre: "San Lorenzo" },
        { Nombre: "Samayac" },
        { Nombre: "San Pablo Jocopilas" },
        { Nombre: "San Antonio Suchitépequez" },
        { Nombre: "San Miguel Panán" },
        { Nombre: "San Gabriel" },
        { Nombre: "Chicacao" },
        { Nombre: "Patulul" },
        { Nombre: "Santa Bárbara" },
        { Nombre: "San Juan Bautista" },
        { Nombre: "Santo Tomás La Unión" },
        { Nombre: "Zunilito" },
        { Nombre: "Pueblo Nuevo" },
        { Nombre: "Río Bravo" },
      ],
    },
    {
      Nombre: "Totonicapán",
      Municipios: [
        { Nombre: "Totonicapán" },
        { Nombre: "San Cristóbal Totonicapán" },
        { Nombre: "San Francisco El Alto" },
        { Nombre: "San Andrés Xecul" },
        { Nombre: "Momostenango" },
        { Nombre: "Santa María Chiquimula" },
        { Nombre: "Santa Lucía La Reforma" },
        { Nombre: "San Bartolo" },
      ],
    },
    {
      Nombre: "Zacapa",
      Municipios: [
        { Nombre: "Cabañas" },
        { Nombre: "Estanzuela" },
        { Nombre: "Gualán" },
        { Nombre: "Huité" },
        { Nombre: "La Unión" },
        { Nombre: "Río Hondo" },
        { Nombre: "San Jorge" },
        { Nombre: "San Diego" },
        { Nombre: "Teculután" },
        { Nombre: "Usumatlán" },
        { Nombre: "Zacapa" },
      ],
    },
  ]

  const set = async (event) => {
    const id = event.target.value
    setDep(id)
    deps.map((list) => {
      if (id == list.Nombre) {
        setSelec(list.Municipios)
      }
    })
  }

  const uppdate = async () => {
    setErr("Actualizado con exito")
    await updateDoc(doc(firestore, "Usuarios", user.id), {
      Direccion: dep + " " + muni + " " + dire,
    })
    setShow(true)
    setTimeout(() => {
      setShow(false)
      setEdit(false)
    }, 2500)
  }

  useEffect(() => {
    if (user == null) {
      route("Register")
    }
  }, [])
  return (
    <div className="p-10 ml-10">
      <Alert msg={err} show={show} />
      <div className="p-8 bg-white shadow mt-24">
        <div className="grid grid-cols-1 xs:grid-cols-3">
          <div className="grid grid-cols-1 text-center order-last xs:order-first mt-20 xs:mt-0">
            <div>
              <p className="font-bold text-gray-700 text-xl">0</p>
              <p className="text-gray-400">Pedidos Realizados</p>
            </div>
          </div>
          <div className="relative">
            <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-24 w-24"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>

          <div className="space-x-8 flex justify-between mt-32 xs:mt-0 xs:justify-center">
            <button
              onClick={() => {
                setEdit(!edit)
              }}
              className="text-white py-2 px-4 uppercase rounded bg-blue-400 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
            >
              Editar Informacion
            </button>
            <button
              onClick={() => {
                logut()
              }}
              className="text-white py-2 px-4 uppercase rounded bg-red-700 hover:bg-red-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
            >
              Cerrar Secion
            </button>
          </div>
        </div>

        <div className="mt-20 text-center border-b pb-12">
          <h1 className="text-4xl font-medium text-gray-700">
            {user.Nombre} {user.Apellido}
            <span className="font-light text-gray-500"> {user.Rango}</span>
          </h1>
          <p className="font-light text-gray-600 mt-3">desde {user.Creado}</p>
        </div>

        {edit == true ? (
          <div className="grid grid-cols-2 xs:grid-cols-1 gap-4">
            <div className="">
              <label
                htmlFor="2"
                className="block mb-2 text-sm font-medium text-black"
              >
                Selecciona Tu Departamento
              </label>
              <select
                onChange={set}
                id="departamentos"
                className=" border text-sm rounded-lg block w-full p-2.5 bg-white  placeholder-gray-400 text-black ring-blue-500 border-blue-500"
              >
                <option key={" "}> </option>
                {deps.map((list, i) => {
                  return <option key={i}>{list.Nombre}</option>
                })}
              </select>
            </div>

            {dep != "" ? (
              <div>
                <label
                  for="muni"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Selecciona Tu Municipio
                </label>
                <select
                  id="Municipio"
                  onChange={(e) => {
                    var munis = e.target.value
                    setMuni(munis)
                  }}
                  className=" border text-sm rounded-lg block w-full p-2.5 bg-white  placeholder-gray-400 text-black ring-blue-500 border-bue-500"
                >
                  <option key={" "}> </option>
                  {selec.map((list) => {
                    return <option>{list.Nombre}</option>
                  })}
                </select>
              </div>
            ) : null}
            {dep != "" && muni != "" ? (
              <div className="mt-5">
                <input
                  type="direccion"
                  value={dire}
                  autoComplete="off"
                  onChange={(e) => {
                    setDire(e.target.value)
                  }}
                  name="direccion"
                  placeholder="Completa Tu Direccion"
                  className="border border-gray-400 py-1 px-2 w-full"
                />
              </div>
            ) : null}

            <button
              onClick={() => {
                if (muni == "" && dep == "" && dire == "") {
                  setErr("Debes llenar todos los campos")
                  setShow(true)

                  setTimeout(() => {
                    setShow(false)
                  }, 2500)
                } else {
                  uppdate()
                }
              }}
              className="text-white py-2 px-4 uppercase rounded bg-blue-400 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
            >
              Actualizar Datos
            </button>
          </div>
        ) : (
          <>
            <div className="mt-12 flex flex-col justify-center">
              <p className="text-gray-600 text-center font-light lg:px-16">
                Direccion De Entrega: {user.Direccion}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
