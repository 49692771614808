// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaDiscord,
  FaWhatsapp,
} from "react-icons/fa";

export const socialData = [
  { href: "/", icon: <FaFacebookF /> },
  { href: "/", icon: <FaWhatsapp /> },
  { href: "/", icon: <FaInstagram /> },
];
