import React from "react"
import { motion } from "framer-motion"
import { fadeIn, staggerContainer } from "../options/variants"
import Header from "./Header"
const Hero = () => {
  return (
    <section className=" bg-footer bg-cover bg-right">
      <div className="container  pl-20 mx-auto flex justify-center items-center">
        <motion.div
          variants={staggerContainer(0.3, 1)}
          initial="hidden"
          whileInView={"show"}
          className="text-center my-24 flex flex-col items-center"
        >
          <motion.div
            variants={fadeIn("down", "tween", 0.2, 1.1)}
            className="text-white text-[24px] font-primary italic font-medium mb-1"
          >
            Mi Tiendita
          </motion.div>
          <motion.h1
            variants={fadeIn("down", "tween", 0.3, 1.1)}
            className="h1 mb-5"
          >
            San Pedro El Alto
          </motion.h1>
          <motion.p
            variants={fadeIn("down", "tween", 0.4, 1.1)}
            className="text-white max-w-[540px] mb-8"
          >
            Antigua Guate
          </motion.p>
          <motion.div variants={fadeIn("down", "tween", 0.5, 1.1)}>
            <button className="btn">Ver</button>
          </motion.div>
        </motion.div>
      </div>
    </section>
  )
}

export default Hero
