import React from "react";
import Lottie from "react-lottie";
import load from "../assets/json/load.json";

export default function Load() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="bg-transparent">
      <Lottie
        className="bg-white"
        options={defaultOptions}
        height={400}
        width={400}
      />
    </div>
  );
}
