import { Navigate } from "react-router-dom"
import { useAuth } from "./authContext"
const ProteReg = ({ children }) => {
  const { user } = useAuth()

  if (user != null) {
    return <Navigate to="/Panel" />
  }
  return children
}

export default ProteReg
