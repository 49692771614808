import logo from "./logo.svg"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./Pages/Home"
import Slider from "./Components/Slider"
import Opciones from "./Pages/Opciones"
import NuevoProd from "./Pages/NuevoProd"
import Register from "./Pages/Register"
import UsPage from "./Pages/userPage"
import Productos from "./Pages/Productos"
import NuevaVenta from "./Pages/NuevaVenta"
import ProtePan from "./Components/ProtePan"
import Load from "./Components/Load"
import ProteReg from "./Components/ProteReg"
import { useAuth } from "./Components/authContext"

function App() {
  const { loading } = useAuth()
  return (
    <div>
      {loading == true ? (
        <Load />
      ) : (
        <>
          <Slider />
          <Routes>
            <Route index element={<Home />} />
            <Route path="Panel" element={<UsPage />} />
            <Route
              path="Opciones"
              element={
                <ProtePan>
                  <Opciones />
                </ProtePan>
              }
            />
            <Route
              path="NuevoProducto"
              element={
                <ProtePan>
                  <NuevoProd />
                </ProtePan>
              }
            />
            <Route
              path="NuevaVenta"
              element={
                <ProtePan>
                  <NuevaVenta />
                </ProtePan>
              }
            />
            <Route
              path="Register"
              element={
                <ProteReg>
                  <Register />
                </ProteReg>
              }
            />
            <Route path="Productos" element={<Productos />} />
          </Routes>
        </>
      )}
    </div>
  )
}

export default App
