import { onAuthStateChanged, signOut } from "firebase/auth"
import { doc, getDoc, onSnapshot, collection } from "firebase/firestore"
import React, { createContext, useContext, useEffect, useState } from "react"
import { auth, firestore } from "../firebase/cliente"

const authContext = createContext()

export const useAuth = () => {
  const context = useContext(authContext)
  if (!context) throw new Error("Error en el provider ")
  return context
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [stck, setStck] = useState([])
  const [clientes, setClientes] = useState([])
  const [clases, setClases] = useState([])
  const [sells, setSells] = useState([])
  const [bolsa, setBolsa] = useState([])
  const [bolsaPorce, setBolsaPorce] = useState(0)
  const [loading, setLoadin] = useState(true)
  const [bolsaSub, setBolsaSub] = useState(0)
  const [bolsaTotal, setBolsaTotal] = useState(0)

  useEffect(() => {
    const cla = collection(firestore, "Clasificaciones")
    const cli = collection(firestore, "Clientes")
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser != null) {
        const refUs = doc(firestore, "Usuarios", currentUser.uid)
        const refSell = collection(firestore, "Ventas")
        onSnapshot(refUs, (snap) => {
          setUser({ id: auth.currentUser.uid, ...snap.data() })
        })
        onSnapshot(
          collection(doc(firestore, "Usuarios", currentUser.uid), "Bolsa"),
          (snap) => {
            let dq = []
            var sum = 0
            snap.forEach((doc) => {
              const data = doc.data()
              const id = doc.id
              sum = sum + data.PrecioTotal
              dq.push({ id: id, ...data })
            })
            setBolsa(dq)
            setBolsaSub(sum)
            var pretot = 35 + (3.5 * sum) / 100 + sum
            setBolsaTotal(pretot)
            setBolsaPorce((3.5 * sum) / 100)
          }
        )
        const snap = await getDoc(refUs)
        if (snap.data().Rango == "Admin") {
          onSnapshot(cla, (snap) => {
            let dq = []
            snap.forEach((doc) => {
              const data = doc.data()
              const id = doc.id
              dq.push({ id: id, ...data })
            })
            dq.sort((a, b) => {
              const is = op == "desc" ? 1 : -1
              return is * a.Nombre.localeCompare(b.Nombre)
            })
            setClases(dq)
          })
          onSnapshot(cli, (snap) => {
            let dq = []
            snap.forEach((doc) => {
              const data = doc.data()
              const id = doc.id
              dq.push({ id: id, ...data })
            })
            dq.sort((a, b) => {
              const is = op == "desc" ? 1 : -1
              return is * a.Nombre.localeCompare(b.Nombre)
            })
            setClientes(dq)
          })
          onSnapshot(refSell, (snap) => {
            let dq = []
            snap.forEach((doc) => {
              const data = doc.data()
              const id = doc.id
              dq.push({ id: id, ...data })
            })
            dq.sort((a, b) => {
              return a.Contador < b.Contador ? 1 : -1
            })
            setSells(dq)
          })
        }
      }
      const db = collection(firestore, "Productos")
      var op = "desc"
      onSnapshot(db, (snap) => {
        let dq = []
        snap.forEach((doc) => {
          const data = doc.data()
          const id = doc.id
          dq.push({ id: id, ...data })
        })
        dq.sort((a, b) => {
          const is = op == "desc" ? 1 : -1
          return is * a.Titulo.localeCompare(b.Titulo)
        })
        setStck(dq)
        setLoadin(false)
      })
    })
    return () => unsubscribe()
  }, [])

  return (
    <authContext.Provider
      value={{
        user,
        stck,
        clases,
        clientes,
        bolsa,
        bolsaSub,
        sells,
        loading,
      }}
    >
      {children}
    </authContext.Provider>
  )
}
